import React, { useContext } from "react";
import { ThemeContext } from "../context/theme.context";
import Utils from "../utils";

const ToggleThemeComponent = () => {
  const { theme, setTheme, isDark } = useContext(ThemeContext);
  const themes = Utils.Themes();

  // Toggle between light and dark mode
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div className="flex ">
      <label className="flex items-center relative cursor-pointer select-none w-full xl:w-fit">
        <input
          type="checkbox"
          className="menuToggleBtn"
          defaultChecked={theme === "dark"}
          onChange={toggleTheme}
        />
        {theme === "dark" ? (
          <span className="onOffText left-2 text-white p1">dark</span>
        ) : (
          <span className="onOffText right-2 text-black p1">light</span>
        )}
        <span
          className={`onOffRing ${theme === "dark" ? "right-10" : "left-2"}`}
        />
      </label>
    </div>
  );
};

export default ToggleThemeComponent;
