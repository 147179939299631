import { useState, memo, useEffect, useContext } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Services from "../../services";
import Utils from "../../utils";
import { Button, ThemeProvider } from "@mui/material";
import CreateBid from "./CreateBid.components";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import {
  Input,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Option,
} from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";
import { ThemeContext } from "../../context/theme.context";

type checkout = {
  setShowModal: any;
  auction_info: any;
  getComments: any;
  getListOfAuction: any;
  hideBidModal?: boolean;
  hideAgreementModal?: any;
  showCreditCard: boolean;
  reloadWindow?: boolean;
  markAsFeatured?: any;
};

type Country = {
  label: string;
  value: string;
};

const CheckoutForm = memo(
  ({
    setShowModal,
    auction_info,
    getComments,
    getListOfAuction,
    hideBidModal,
    hideAgreementModal,
    showCreditCard,
    reloadWindow,
    markAsFeatured,
  }: checkout) => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    const location = useLocation();
    const themes = Utils.Themes();
    const { theme, isDark } = useContext(ThemeContext);
    let user = Services.Storage.getCurrentUser();
    const isAuctionPage = location?.pathname?.split("/")?.[1] === "auctions";
    const navigate = useNavigate();
    const [countryOptions, setCountries] = useState<Country[]>([
      {
        label: t("countries.ch"),
        value: "ch",
      },
      {
        label: t("countries.eu"),
        value: "eu",
      },
      {
        label: t("countries.uk"),
        value: "uk",
      },
      {
        label: t("countries.us"),
        value: "us",
      },
      {
        label: t("countries.ca"),
        value: "ca",
      },
      {
        label: t("countries.uae"),
        value: "uae",
      },
      {
        label: t("checkout_form.location_options.2"),
        value: t("checkout_form.location_options.2"),
      },
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showBidModal, setShowBidModal] = useState<boolean>(false);
    const [newCard, setNewCard] = useState<boolean>(true);
    const [userCards, setUserCards] = useState<any[]>([]);
    const [selectedCard, setSelectedCard] = useState<any>({});
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [country, setCountry] = useState<string>("");

    const formik = useFormik({
      initialValues: {
        name: "",
        country: "",
        phone: "",
        selected_card: "",
      },
      validationSchema: Yup.object().shape({
        name: newCard
          ? Yup.string().required().trim().min(3).max(20)
          : Yup.string(),
        country: newCard ? Yup.string().required() : Yup.string(),
        phone: newCard ? Yup.string().required().min(8).max(11) : Yup.string(),
        selected_card: newCard
          ? Yup.string()
          : Yup.string().required("Card is requried"),
      }),
      onSubmit: async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        handleSubmit();
      },
    });

    const handleSubmit = async () => {
      if (!errorMessage) setLoading(true);
      if (!stripe || !elements) {
        console.log("stripe not loaded");
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        if (reloadWindow) {
          window.location.reload();
          return navigate("/");
        }
        return;
      }

      if (!newCard) {
        if (!formik?.values?.selected_card) {
          return;
        } else {
          if (isAuctionPage) {
            return setShowBidModal(true);
          } else if (markAsFeatured) {
            return markAsFeatured(formik?.values?.selected_card);
          } else {
            return window.location.reload();
          }
        }
      } else {
        if (errorMessage) {
          return;
        }
      }

      const cardElement = elements.getElement("card");
      const data = { name: formik?.values?.name };

      if (cardElement) {
        stripe
          .createToken(cardElement, data)
          .then((payload) => {
            setSelectedCard(payload?.token?.card?.id);
            if (payload?.token?.id) {
              const data = {
                token: payload?.token?.id,
                country: formik?.values?.country,
                phone_number: formik?.values?.phone,
              };
              Services.User.SaveCard(data, location?.state?.token)
                .then((res: any) => {
                  setLoading(false);
                  if (markAsFeatured) {
                    return markAsFeatured(payload?.token?.card?.id);
                  }
                  if (reloadWindow) {
                    window.location.reload();
                    return navigate("/");
                  }
                  if (!isAuctionPage && !user.is_registered_bidder) {
                    return navigate("/");
                  }
                  if (res.status) {
                    setShowBidModal(!hideBidModal && isAuctionPage);
                    if (!user.is_registered_bidder) {
                      user.is_registered_bidder = true;
                      Services.Storage.updateUser(user);
                      return window.location.reload();
                    }
                  }
                  hideAgreementModal && hideAgreementModal();
                })
                .catch((error: any) => {
                  setLoading(false);
                  if (reloadWindow) {
                    window.location.reload();
                    return navigate("/");
                  }
                  if (!isAuctionPage) {
                    return navigate("/");
                  }
                });
            } else {
              setLoading(false);
              if (!isAuctionPage) {
                return navigate("/");
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            if (reloadWindow) {
              window.location.reload();
              return navigate("/");
            }
            if (!isAuctionPage) {
              return navigate("/");
            }
          });
      }
    };

    const cardElementOptions = {
      style: {
        base: {
          fontSize: "16px",
          color: isDark ? "white" : "#000000",
          "::placeholder": {
            color: isDark ? "white" : "#e3e3e3",
            fontSize: "16px",
          },
          border: "1px solid " + (isDark ? "var(--gray)" : "#7963f0"), // Add border styling
          borderRadius: "6px", // Optional: Add border-radius for rounded corners
          padding: "24px 32px", // Optional: Add padding for better visual appearance,
          paddingTop: "32px",
          boxShahdow: isDark
            ? "var(--joy-shadowRing, 0 0 #fff),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 255 255 255) / var(--joy-shadowOpacity, 0.08))"
            : "var(--joy-shadowRing, 0 0 #fff),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 255 255 255) / var(--joy-shadowOpacity, 0.08))",
          background: isDark ? "var(--dark)" : "#fbfcfe",
        },
        invalid: {
          color: "#d3232f",
        },
        theme: isDark ? "night" : "stripe",
      },
      labels: "floating",
      hidePostalCode: false,
    };

    const fetchUserCreditCards = () => {
      setIsLoading(true);
      Services.User.FetchCards(offset, limit)
        .then((res: any) => {
          setIsLoading(false);
          if (res?.status && res?.data?.length > 0) {
            let currentMonth = new Date().getUTCMonth();
            let currentYear = new Date().getUTCFullYear();
            let userCars: any[] = [];
            res?.data?.map((item: any) => {
              (item.exp_year > currentYear ||
                (item.exp_year == currentYear &&
                  item.exp_month == currentMonth)) &&
                userCars.push({
                  ...item,
                  label:
                    (item?.name?.split(" ")?.length > 0
                      ? item?.name
                          ?.split(" ")
                          ?.map((n: any) => {
                            return n
                              ? n[0]?.toUpperCase() +
                                  (n.substring(1)?.toLowerCase()
                                    ? n.substring(1)?.toLowerCase()
                                    : "")
                              : "";
                          })
                          ?.join(" ")
                      : item?.name) +
                    " " +
                    item?.brand?.toUpperCase() +
                    " " +
                    t("checkout_form.expired_at_text") +
                    " " +
                    item.exp_month +
                    "-" +
                    item.exp_year,
                });
            });
            setUserCards(userCars);
            setNewCard(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      showCreditCard && fetchUserCreditCards();
    }, [showCreditCard]);

    const handleOnBlur = async (event: any) => {
      if (newCard && stripe && elements) {
        const cardElement = elements.getElement("card");
        const data = {
          name: formik?.values?.name,
        };
        if (cardElement) {
          stripe
            .createToken(cardElement, data)
            .then((payload) => {
              setErrorMessage(payload?.error?.message || "");
            })
            .catch((error) => {
              setErrorMessage(error || "");
            });
        }
      }
    };

    const hanldeOnChange = async (event: any) => {
      return setErrorMessage(
        event?.error?.message
          ? event?.error?.message
          : event?.empty
            ? "card details are required"
            : ""
      );
    };

    // console.log(
    //   "month",
    //   new Date().toUTCString(),
    //   new Date().toISOString(),
    //   new Date().toLocaleString()
    // );

    return (
      <>
        {loading && <PreLoaderComponent2 />}
        {isLoading && (
          <div className="w-full flex flex-col text-gray-700 gap-2 h-full mb-4">
            {_.times(5, (index) => (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={"1.5rem"}
                key={index}
              />
            ))}
          </div>
        )}
        {!isLoading && (
          <div>
            {userCards?.length > 0 && (
              <div className="pb-4 flex gap-2 items-center">
                <div className="p1">
                  <input
                    type="radio"
                    name="cards"
                    onChange={(e) => setNewCard(true)}
                    className="text-[--main-color] radioInput"
                  />
                  <label className="px-1  dark:text-white" htmlFor="cards">
                    {t("checkout_form.new_card_label")}
                  </label>
                </div>
                <div className="p1">
                  <input
                    type="radio"
                    name="cards"
                    onChange={(e) => setNewCard(false)}
                    checked={!newCard}
                    className="text-[--main-color] radioInput"
                  />
                  <label className="px-1 dark:text-white" htmlFor="cards">
                    {t("checkout_form.saved_card_label")}
                  </label>
                </div>
              </div>
            )}
            <form onSubmit={formik?.handleSubmit}>
              {userCards?.length > 0 && !newCard ? (
                <div className="flex-col w-full pb-4 p1">
                  <label className="px-1 inputLabel" htmlFor="creditCards">
                    {t("checkout_form.credit_card_label")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Select
                    color="neutral"
                    placeholder="Select a Credit Cards"
                    onChange={(e: any, value: any) => {
                      formik?.setFieldTouched("selected_card", true);
                      formik?.setFieldValue("selected_card", value?.id);
                      setSelectedCard(value?.id);
                    }}
                    name="creditCards"
                    onBlur={(e: any) => {
                      formik?.setFieldTouched("selected_card", true);
                    }}
                    sx={
                      formik?.errors?.selected_card &&
                      formik?.touched?.selected_card
                        ? themes.JoyInputError_2
                        : themes.JoyGrayShadeInputStyle2_2
                    }
                  >
                    {userCards?.length > 0 &&
                      userCards?.map((item: any, index: number) => (
                        <Option value={item} key={index}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                  <WarningAlert
                    message={
                      formik?.touched?.selected_card
                        ? formik?.errors?.selected_card
                        : ""
                    }
                  />
                </div>
              ) : (
                <>
                  <div className="flex-col w-full pb-4 p1">
                    <label className="px-1 inputLabel" htmlFor="name">
                      {t("checkout_form.name_label")}
                      <sup className="requireField">*</sup>
                    </label>
                    <Input
                      name="name"
                      variant="outlined"
                      size="sm"
                      value={formik?.values?.name}
                      onChange={formik?.handleChange}
                      slotProps={{
                        input: {
                          maxLength: 150,
                          width: "100%",
                        },
                      }}
                      placeholder="Name"
                      onBlur={formik.handleBlur}
                      sx={
                        formik?.touched?.name && formik?.errors?.name
                          ? themes.JoyInputError_2
                          : themes.JoyGrayShadeInputStyle2_2
                      }
                      className="2xl:py-2"
                    />
                    <WarningAlert
                      message={
                        formik?.touched?.name ? formik?.errors?.name : ""
                      }
                    />
                  </div>
                  <div className="flex-col w-full pb-4 p1">
                    <label
                      htmlFor="card-details"
                      className="py-3 font-bold inputLabel"
                    >
                      {t("checkout_form.card_details_label")}
                    </label>
                    <div
                      className={`border  bg-[var(--input-bg-faded)] shadow px-[16px] py-[12px] text-[16px] rounded-[6px] darkShadow dark:text-white dark:bg-[--dark] ${
                        errorMessage
                          ? "border-[var(--error-color)] hover:border-[var(--error-color)]"
                          : "border-[var(--mid-gray-color)] hover:border-[var(--main-color)] dark:border-[--gray] dark:hover:border-[--gray]"
                      }`}
                    >
                      <CardElement
                        id="card-details"
                        options={cardElementOptions}
                        onChange={hanldeOnChange}
                        onBlur={handleOnBlur}
                      />
                    </div>
                    <WarningAlert message={errorMessage} />
                  </div>
                  <div className="pb-4">
                    <h4 className="font-bold head">
                      {t("checkout_form.contact_title")}
                    </h4>
                    <p className="p1 opacity-75 pb-2">
                      {t("checkout_form.phone_number_label")}
                    </p>
                    <div>
                      <div className="flex justify-start items-center gap-2 p1">
                        <Autocomplete
                          disableClearable={true}
                          name="country"
                          options={countryOptions}
                          onChange={(e: any, selectedOption: any) => {
                            formik?.setFieldTouched("country", true, false);
                            formik?.setFieldValue(
                              "country",
                              selectedOption.value
                            );
                            setCountry(selectedOption.label);
                          }}
                          onBlur={formik?.handleBlur}
                          placeholder="Select a Country"
                          sx={
                            formik?.errors?.country && formik?.touched?.country
                              ? themes.JoyInputError_2
                              : themes.JoyGrayShadeInputStyle2_2
                          }
                        />
                        <div className="InputFieldSetting">
                          <Input
                            name="phone"
                            variant="outlined"
                            size="sm"
                            value={formik?.values?.phone}
                            onChange={formik?.handleChange}
                            slotProps={{
                              input: {
                                maxLength: 150,
                              },
                            }}
                            type="number"
                            placeholder="Phone Number"
                            onBlur={formik.handleBlur}
                            sx={
                              formik?.touched?.phone && formik?.errors?.phone
                                ? themes.JoyInputError_2
                                : themes.JoyGrayShadeInputStyle2_2
                            }
                            className="2xl:py-2 w-full"
                          />
                        </div>
                      </div>
                      <WarningAlert
                        message={
                          formik?.touched?.phone ? formik?.errors?.phone : ""
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="pb-4">
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    variant="contained"
                    color={loading || isLoading ? "inherit" : "primary"}
                    size="medium"
                    sx={{ textTransform: "none" }}
                    type="submit"
                    disabled={loading || isLoading}
                    fullWidth
                  >
                    {userCards?.length > 0
                      ? t("checkout_form.submit_button_texts.1")
                      : t("checkout_form.submit_button_texts.2")}
                  </Button>
                </ThemeProvider>
              </div>
            </form>
          </div>
        )}

        {showBidModal && (
          <CreateBid
            showBid={showBidModal}
            setShowBid={setShowBidModal}
            setShowCheckoutModal={setShowModal}
            auction_info={auction_info}
            getListOfAuction={getListOfAuction}
            getComments={getComments}
            selectedCard={selectedCard}
            hideAgreementModal={hideAgreementModal}
          />
        )}
      </>
    );
  }
);

export default CheckoutForm;
