import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { NavComponent, FooterComponent } from "../components";

function NotFoundLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dark:bg-[--dark]">
      <div className="flex flex-col h-full w-full min-h-screen xl:container mx-auto">
        <NavComponent />
        <div className="">
          <Outlet />
        </div>
        <FooterComponent />
      </div>
    </div>
  );
}

export default NotFoundLayout;
