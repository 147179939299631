import React from "react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputBase, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Input } from "@mui/joy";
import Utils from "../../utils";

type SearchTypeProps = {
  search?: string;
  setSearch?: any;
  classes?: string;
};

const SearchComponent = ({ search, setSearch, classes }: SearchTypeProps) => {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  return (
    <>
      <div
        className={`InputFieldSetting w-[260px] sm:w-[320px] xl:w-[360px] ${classes}`}
      >
        <Input
          name="search"
          placeholder={t("listing_dashboard_page.xujr6pw64")}
          onChange={(e) => setSearch && setSearch(e.target.value)}
          value={search}
          variant="outlined"
          type="text"
          className="search  w-full transition-all ease-in-out peo"
          sx={themes.JoyGrayShadeInputStyle2_2}
          slotProps={{
            input: {
              max: 255,
              "aria-label": "search",
            },
          }}
          startDecorator={<SearchIcon sx={{ color: "var(--main-color)" }} />}
          endDecorator={
            <ThemeProvider theme={themes.ButtonActiveTheme_2}>
              <Button type="submit" variant="contained" className="">
                Search
              </Button>
            </ThemeProvider>
          }
        />
      </div>
    </>
  );
};

export default SearchComponent;
