import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import Swal from "sweetalert2";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

function Verification() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Services.Auth.IsUserLogedIn() && navigate(-1);
  }, []);

  const VerifyEmail = (email: string, accessToken: string) => {
    Services.Auth.VerifyEmail(email, accessToken)
      .then((res) => {
        if (res.status == true) {
          Services.Storage.setSession(res.data.access_token, res.data);
          Services.Storage.setUserRole(res.data.role);
          if (location?.pathname === "/admin/verify-email") {
            Swal.fire({
              icon: "success",
              title: t("verfication_page.sweat_alert.success_title"),
            });
            navigate('/dashboard')
            return window.location.reload();
          } else {
            return navigate("/", {
              state: { openVerify: true, reloadWindow: true, token: res.data.access_token },
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: t("verfication_page.sweat_alert.verify_email_error_title"),
          });
          return navigate("/");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
        return navigate("/");
      });
  };

  useEffect(() => {
    if (location?.search != "") {
      const searchInfo = location.search.split("&");
      const email = searchInfo[0].split("=")[1];
      const accessToken = searchInfo[1].split("=")[1];
      return VerifyEmail(email, accessToken);
    } else {
      Swal.fire({
        icon: "error",
        title: t("verfication_page.sweat_alert.verify_email_error_title"),
      });
      return navigate("/");
    }
  }, [location]);

  return (
    <>
      <PageComponents.MetaComponent title={t("verfication_page.title")} />
      <div className=" pt-12 sm:pt-20 md:pt-24 xl:pt-32">
        <div className="container mx-auto flex  justify-center items-center p-10 pb-0 sm:pb-10">
          <div className="w-full sm:w-5/6 md:w-4/6 lg:w-3/6 xl:w-2/6 px-6   sm:py-12 pt-12 pb-0 border borderColor darkShadow flex flex-col justify-center items-center shadow-lg drop-shadow-sm rounded">
            <Link
              className="flex title-font font-medium items-center text-gray-900  md:mb-0 "
              to="/"
            >
              <img
                src={AppImages.LogoImage}
                className="md:h-12 h-10"
                alt="AutoBids Logo"
              />
              <h1 className="ml-3 head capitalize">{t("site_title")}</h1>
            </Link>
            <div className="">
              <p className="p0 text-semibold text-center pt-2">
                {t("verfication_page.description")}
              </p>
              <h1 className="text-center head text-bold py-4">
                {t("verfication_page.heading")}...
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verification;
