import { Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext } from "react";
import { ThemeContext } from "../../context/theme.context";

const CardSkeletonComponent = () => {
  const { isDark } = useContext(ThemeContext);

  const style = {
    borderRadius: "0.5rem",
    height: "26px",
    background: isDark ? "var(--gray)" : "var(--light)",
  };

  return (
    <div className="listingCard gap-2">
      <Skeleton
        style={{
          ...style,
          borderRadius: "2rem",
          width: "100px",
        }}
      />
      <Skeleton
        style={{
          ...style,
        }}
      />
      <Skeleton
        style={{
          ...style,
          height: "240px",
        }}
      />
      <Skeleton
        style={{
          ...style,
        }}
      />
      <Skeleton
        style={{
          ...style,
        }}
      />
      <Skeleton
        style={{
          ...style,
        }}
      />
      <Skeleton
        style={{
          ...style,
        }}
      />
    </div>
  );
};

export default CardSkeletonComponent;

const CardSkeletonElements = () => {
  let skeletons: JSX.Element[] = [];
  for (let i = 0; i < 3; i++) {
    skeletons.push(
      <Grid lg={4} md={6} xs={12} key={i}>
        <div className="p-1.5">
          <CardSkeletonComponent />
        </div>
      </Grid>
    );
  }

  return skeletons;
};

export { CardSkeletonElements };
