import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import { ConvertToLocal } from "../../utils/CalculateDays";
import { Link, useLocation } from "react-router-dom";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { useDevice, useScreen } from "../../hooks";
import { ShareModal } from "../../components";

interface FeaturedProps {
  data: any;
  addToWatchList: (id: string | number) => void;
  removeFromWatchList: (id: string | number) => void;
}

const FeaturedCardComponent = ({
  data,
  addToWatchList,
  removeFromWatchList,
}: FeaturedProps) => {
  const device = useDevice();
  const { t } = useTranslation();
  const location = useLocation();
  const url = `/auctions/${
    data?.year?.replace(/\s+/g, "-") +
    "-" +
    data?.make?.replace(/\s+/g, "-") +
    "-" +
    data?.model?.replace(/\s+/g, "-") +
    (data?.title ? "-" + data?.title?.replace(/\s+/g, "-") : "")
  }/${data?.uuid}`;
  const imagePath = `${process.env.REACT_APP_HOST_API_KEY}/`;
  const [loading, setLoading] = useState(false);
  const [isFeaturedPage, setIsFeaturedPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsFeaturedPage(location?.pathname === "/featured-auctions");
  }, [location?.pathname]);

  return (
    <div className="w-full no-underline" key={data?.id}>
      {/* top bar */}
      <Link
        className={`flex flex-col md:flex-row  justify-between items-start pt-0 lg:pt-6 2xl:pt-8 sm:pt-0 w-full md:h-[350px] lg:h-[400px] 2xl:h-[500px]`}
        to={url}
      >
        {/* left card */}
        <div className="md:w-1/2 xl:w-7/12 w-full cursor-pointer relative h-full">
          <img
            src={imagePath + data?.photos?.exterior[0]}
            className="w-full rounded-t md:rounded-tr-none md:rounded-l  shadow-sm hover:shadow-md h-full object-cover "
          />
          {/* image overlay */}
          <div className="featuredOverlay rounded-t md:rounded-tr-none md:rounded-l" />
          {/* featured  label*/}
          <div className="absolute top-2 left-1.5 w-fit h-fit py-0.5 px-2 bg-[--main-color] rounded shadow">
            <p className="text-[var(--mid-gray-color)] p2">Featured</p>
          </div>
          {/* title for extra large screen */}
          {!isFeaturedPage && (
            <div className="absolute top-2 right-1.5 h-fit text-white text-right hidden xl:block ps-1 sm:ps-2 xl:w-4/5">
              {/* title */}
              <h2 className="truncate p0 capitalize drop-shadow-md">{data?.title || " "}</h2>
              {/* make . year . model */}
              <div className="p1 truncate flex justify-end items-center gap-1 capitalize dark:text-[--light] drop-shadow-md">
                {data?.make}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-1 h-1"
                  fill="currentColor"
                  stroke="currentColor"
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"></path>
                </svg>
                {data?.model + " " + data?.year}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-1 h-1"
                  fill="currentColor"
                  stroke="currentColor"
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"></path>
                </svg>
                {data?.mileage || 0} KM
              </div>
            </div>
          )}
          {/* time and bid  */}
          <div className="absolute bottom-2 left-1.5 w-fit h-fit px-2 bg-[--light-color] rounded shadow z-9999 p2">
            <div className="flex justify-between items-center gap-1">
              {/* auction time left details */}
              <div className="py-0.5">
                <Tooltip
                  title={
                    t("time-left") +
                    " " +
                    (Utils.CalculateDays(data?.auction?.auction_end) > 1 ? (
                      Utils.CalculateDays(data?.auction?.auction_end)
                        ?.toFixed(0)
                        .toString() +
                      " " +
                      t("auction_details_page.days")
                    ) : (
                      <Countdown
                        date={ConvertToLocal(data?.auction?.auction_end)}
                        daysInHours={true}
                      />
                    ))
                  }
                >
                  <div className="flex justify-start items-center gap-1 sm:gap-2 ">
                    <span className="text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-3.5 h-3.5 sm:w-4 sm:h-4"
                      >
                        <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                      </svg>
                    </span>
                    <span className="text-black">
                      {Utils.CalculateDays(data?.auction?.auction_end) > 1 ? (
                        Utils.CalculateDays(data?.auction?.auction_end)
                          ?.toFixed(0)
                          .toString() +
                        " " +
                        t("auction_details_page.days")
                      ) : (
                        <Countdown
                          date={ConvertToLocal(data?.auction?.auction_end)}
                          daysInHours={true}
                        />
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>
              {/* bid details */}
              <div className="border-l border-white ps-2 py-0.5">
                <Tooltip
                  title={
                    t("card_data.auction_status.bid") +
                    " " +
                    (data?.current_bid?.price ? data?.current_bid?.price : 0) +
                    " CHF"
                  }
                >
                  <div className="flex justify-start items-center gap-1">
                    <span className="text-gray-500">
                      {t("card_data.auction_status.bid")}
                    </span>
                    <span className="text-black">
                      {data?.current_bid?.price ? data?.current_bid?.price : 0}{" "}
                      CHF
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {/* right card */}
        <div
          className={`md:w-1/2 xl:w-5/12 w-full showcase-images pt-0.5 md:pt-0 md:ps-0.5 overflow-hidden h-full`}
        >
          <div className="h-full">
            <ImageList
              sx={{
                boxShadow: "none",
                borderRadius: "0",
                height: "100%",
              }}
            >
              <ImageListItem className="relative md:h-1/2">
                <img
                  src={imagePath + data?.photos?.exterior[1]}
                  srcSet={imagePath + data?.photos?.exterior[1]}
                  className={`w-full object-cover cursor-pointer rounded-bl md:rounded-bl-none h-full`}
                />
                <div className="featuredOverlay rounded-bl md:rounded-bl-none" />
              </ImageListItem>
              <ImageListItem className="relative md:h-1/2">
                <img
                  src={imagePath + data?.photos?.interior[0]}
                  srcSet={imagePath + data?.photos?.interior[0]}
                  className={`w-full object-cover cursor-pointer rounded-br md:rounded-br-none md:rounded-tr h-full`}
                />
                <div className="featuredOverlay rounded-br md:rounded-br-none md:rounded-tr" />
              </ImageListItem>
              {!device.is_sm && (
                <ImageListItem className="hidden md:flex relative h-1/2">
                  <img
                    src={imagePath + data?.photos?.mechanical[0]}
                    srcSet={imagePath + data?.photos?.mechanical[0]}
                    className={`w-full h-full object-cover cursor-pointer`}
                  />
                  <div className="featuredOverlay" />
                </ImageListItem>
              )}
              {!device.is_sm && (
                <ImageListItem className="hidden md:flex relative h-1/2">
                  <img
                    src={imagePath + data?.photos?.exterior[2]}
                    srcSet={imagePath + data?.photos?.exterior[2]}
                    className={`w-full h-full object-cover cursor-pointer rounded-br`}
                  />
                  <div className="featuredOverlay rounded-br" />
                </ImageListItem>
              )}
            </ImageList>
          </div>
        </div>
      </Link>
      {/* bottom bar */}
      {/* title for large and small screens  */}
      <div className={`pt-2 ${!isFeaturedPage ? "xl:hidden " : ""}`}>
        <div className="flex justify-between items-start gap-4">
          <Link to={url}>
            <h2 className="truncate p0 text-black dark:text-white text-left capitalize text-wrap">
              {data?.title || " "}
            </h2>
          </Link>
          <div className="flex justify-between items-baseline gap-2">
            <button
              className={`textColor flex-col gap-1 cursor-pointer dark:text-[--light] ${
                data?.watching ? "" : "displayONHover"
              }  transition-all dhrOnhover p1`}
              onClick={() =>
                isUser_loged_in()
                  ? data?.watching
                    ? removeFromWatchList(data?.id)
                    : addToWatchList(data?.id)
                  : document.getElementById("signin")?.click()
              }
              disabled={loading}
            >
              {data?.watching ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  fill="currentColor"
                  className="w-4 h-auto"
                >
                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-auto dark:fill-white"
                  viewBox="0 0 576 512"
                >
                  <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                </svg>
              )}
              <hr className="bgColor rounded p-[1px] shadow-sm" />
            </button>
            {!device.is_sm && isFeaturedPage && (
              <button
                type="button"
                className="flex justify-center items-baseline p00 dark:text-[--light]"
                onClick={() => setIsModalOpen(true)}
              >
                {t("auction_details_page.share")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-full ml-2"
                >
                  <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                </svg>
              </button>
            )}
          </div>
        </div>
        {/* make . year . model */}
        <Link
          to={url}
          className="p1 truncate flex flex-wrap justify-start items-center gap-1 capitalize text-gray-500 dark:text-[--light]"
        >
          {data?.make}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-1 h-1"
            fill="currentColor"
            stroke="currentColor"
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"></path>
          </svg>
          {data?.model + " " + data?.year}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-1 h-1"
            fill="currentColor"
            stroke="currentColor"
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"></path>
          </svg>
          {data?.mileage || 0} KM
        </Link>
      </div>
      {/* share modal */}
      {isModalOpen && (
        <ShareModal setIsModalOpen={setIsModalOpen} auctionInfo={data} />
      )}
    </div>
  );
};

export default FeaturedCardComponent;
