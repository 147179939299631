import React, { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import PasswordInputField from "../../components/PasswordInputField.component";
import ForgotPassword from "./ForgotPassword.component.page";
import Services from "../../services";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";

type typeOfForm = String;

const ChangePassword = memo(function ChangePassword({
  changePasswordIsModalOpen,
  setChangePasswordIsModalOpen,
}: any) {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [isLoading, setIsLoading] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<typeOfForm>("");
  const [newPassword, setNewPassword] = useState<typeOfForm>("");
  const [cNewPassword, setcNewPassword] = useState<typeOfForm>("");

  const handleSubmit = () => {
    setIsLoading(true);
    setChangePasswordIsModalOpen(false);

    let data = {
      old_password: currentPassword.trim(),
      password: newPassword.trim(),
    };

    Services.Profile.ChangePassword(data)
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        resetForm();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
        resetForm();
      });
  };

  const resetForm = () => {
    setCurrentPassword("");
    setNewPassword("");
    setcNewPassword("");
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={changePasswordIsModalOpen}
          hideBackdrop={true}
          onClose={() => {
            setChangePasswordIsModalOpen(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet variant="outlined" sx={themes.JoyModalSheetStyle_2}>
            <ModalClose
              variant="outlined"
               size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                cNewPassword: "",
              }}
              onSubmit={async (values) => {
                await new Promise((resolve) => setTimeout(resolve, 500));
                handleSubmit();
              }}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string()
                  .trim()
                  .required(
                    t(
                      "change_password_modal.validation.currentPassword.required_message"
                    )
                  ),
                newPassword: Yup.string()
                  .trim()
                  .required(
                    t(
                      "change_password_modal.validation.newPassword.required_message"
                    )
                  )
                  .test(
                    "equal",
                    t("change_password_modal.validation.newPassword.test"),
                    function (v) {
                      // Don't use arrow functions
                      const ref = Yup.ref("currentPassword");
                      return v !== this.resolve(ref);
                    }
                  )
                  .max(
                    20,
                    t(
                      "change_password_modal.validation.newPassword.max_length_message"
                    )
                  )
                  .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\])[A-Za-z\d@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\]{6,20}$/,
                    t(
                      "change_password_modal.validation.newPassword.match_message"
                    )
                  )
                  .min(
                    6,
                    t(
                      "change_password_modal.validation.newPassword.min_length_message"
                    )
                  ),
                cNewPassword: Yup.string()
                  .trim()
                  .required(
                    t(
                      "change_password_modal.validation.cNewPassword.required_message"
                    )
                  )
                  .oneOf(
                    [Yup.ref("newPassword")],
                    t("change_password_modal.validation.cNewPassword.one_of")
                  ),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className=" w-100">
                      <div className="flex flex-row ">
                        <div className=" w-full">
                          <div className="mx-auto my-auto  flex items-center justify-center h-12 w-12 dark:text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-26 h-26 mb-3"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                              />
                            </svg>
                          </div>
                          <h3 className="head text-center leading-6 font-bold text-gray-900 mb-8">
                            {t("change_password_modal.heading")}
                          </h3>
                          <div className="py-2">
                            <PasswordInputField
                              // Current password field name
                              fieldName={t(
                                "change_password_modal.form.change_password"
                              )}
                              setPassword={setCurrentPassword}
                              password={values.currentPassword}
                              required={true}
                              name="currentPassword"
                              id="currentPassword"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              formikProps={props}
                            />
                            {errors.currentPassword &&
                              touched.currentPassword && (
                                <WarningAlert
                                  message={errors.currentPassword}
                                />
                              )}
                          </div>
                          <div className="py-2">
                            <PasswordInputField
                              // label
                              fieldName={t(
                                "change_password_modal.form.new_password"
                              )}
                              setPassword={setNewPassword}
                              password={values.newPassword}
                              required={true}
                              name="newPassword"
                              id="newPassword"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              formikProps={props}
                            />
                            {errors.newPassword && touched.newPassword && (
                              <WarningAlert message={errors.newPassword} />
                            )}
                          </div>
                          <div className="py-2">
                            <PasswordInputField
                              // label
                              fieldName={t(
                                "change_password_modal.form.re_enter_password"
                              )}
                              setPassword={setcNewPassword}
                              password={values.cNewPassword}
                              required={true}
                              name="cNewPassword"
                              id="cNewPassword"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              formikProps={props}
                            />
                            {errors.cNewPassword && touched.cNewPassword && (
                              <WarningAlert message={errors.cNewPassword} />
                            )}
                          </div>
                          <div className="mt-4 ">
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                variant="contained"
                                type="submit"
                                className=""
                                disabled={isLoading || isSubmitting}
                                fullWidth
                              >
                                {t(
                                  "change_password_modal.form.change_password_butn"
                                )}
                              </Button>
                            </ThemeProvider>
                          </div>

                          <h3
                            className="p1 font-normal textColor cursor-pointer"
                            onClick={() => {
                              setShowForgot(!showForgot);
                              setChangePasswordIsModalOpen(false);
                            }}
                          >
                            {t(
                              "change_password_modal.form.forgot_your_password_heading"
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Sheet>
        </Modal>
      </div>
      <ForgotPassword open={showForgot} setOpen={setShowForgot} />
    </>
  );
});

export default ChangePassword;
