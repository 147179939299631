import { useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import _ from "lodash";
import { AccessTime } from "@mui/icons-material";

type TimeMask = {
  t?: any;
  setT?: any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  formType?: string;
};

const TimeMask = ({ t, setT, name, id, isDisabled, formType }: TimeMask) => {
  const [time, setTime] = useState<string>("");
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const minuteArr = [0, 15, 30, 45];

  useEffect(() => {
    t && setTime(t);
  }, [t, formType]);

  let handleChange = (hour: number, minute: number) => {
    setTime(
      (hour < 10 ? "0" + hour : hour) +
        ":" +
        (minute < 10 ? "0" + minute : minute)
    );
  };

  useEffect(() => {
    setT && setT(time);
  }, [time]);

  const handleTimeValues = () => {
    if (time) {
      setHour(Number(time.split(":")[0]));
      setMinute(Number(time.split(":")[1]));
    }
    return;
  };

  useEffect(() => {
    handleTimeValues();
  }, [formType, time]);

  return (
    <div className="w-full  " key={id ? id : "anykey"}>
      <Listbox disabled={isDisabled}>
        <div className=" mt-1">
          <Listbox.Button
            className={
              "relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border focus-visible:border-[--main-color] focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 p1 border-2 focus:border-[var(--main-color)] dark:bg-[--dark] dark:border-[--gray] darkShadow dark:focus-visible:border-[--main-color]"
            }
            style={{
              opacity: isDisabled ? 0.5 : 1,
            }}
          >
            <input
              className="w-full focus:outline-none dark:bg-[--dark]"
              placeholder="--:--"
              value={time}
              name={name ? name : ""}
              id={id ? id : ""}
            />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <AccessTime className="text-[var(--main-color)] p1" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-fit mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-2xl ring-1 ring-black/5 focus:outline-none p1 z-[9999999] pb-2 dark:bg-[--dark] dark:ring-[--gray] darkShadow">
              <div
                className={`relative cursor-default select-none py-2 pl-2 pr-2`}
              >
                <>
                  <div className="flex h-[160px] gap-2 ">
                    <div className="overflow-y-auto w-[75px] px-2 scrollHiden">
                      {_.times(24, (index: number) => (
                        <div
                          className={`text-center py-1 p1 ${
                            index === hour
                              ? "bg-[var(--main-color)] text-white dark:text-white"
                              : ""
                          } `}
                          onClick={() => {
                            setHour(index);
                            handleChange(index, minute);
                          }}
                        >
                          {index <= 9 && 0}
                          {index}
                        </div>
                      ))}
                    </div>
                    <div className="overflow-y-auto w-[75px] px-2 scrollHiden">
                      {minuteArr?.length > 0 &&
                        minuteArr?.map((item: number, index: number) => (
                          <div
                            className={`text-center py-1  p1 ${
                              item === minute
                                ? "bg-[var(--main-color)] text-white"
                                : ""
                            } `}
                            onClick={() => {
                              setMinute(item);
                              handleChange(hour, item);
                            }}
                          >
                            {item == 0 && 0}
                            {item}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default TimeMask;
