import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../context/theme.context";
import { useContext } from "react";

export default function SliderWrapper(props: any) {
  const { isDark } = useContext(ThemeContext);

  const PrettoSlider = styled(Slider)({
    color: "var(--dark-color)",
    height: 16,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "white",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 10px -30px, rgba(0, 0, 0, 0.3) 0px 2px 10px -3px, rgba(10, 37, 64, 0.35) 0px -0px 4px -1px inset",

      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "unset",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 16,
      background: "unset",
      padding: 0,
      width: 16,
      height: 16,
      backgroundColor: "inherit",
      color: isDark ? "White" : "black",
      fontWeight: "bold",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        background: "transparent",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  return <PrettoSlider style={{ marginTop: "24px" }} {...props} />;
}
