import React from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Images } from "../../assets";
import { Autocomplete, Input } from "@mui/joy";
import Utils from "../../utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Financing: any = () => {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  return (
    <>
      <div className="py-2 ">
        <div className="flex flex-col justify-center items-center">
          <img src={Images.CarIcon} alt="truck" className="w-16 h-12" />
          <h2 className="head font-semibold pt-2">
            {t("auction_details_page.quote_modal.financing.title")}
          </h2>
        </div>
        <div className="py-4 border-b-2 p1 dark:border-[--gray]">
          <p>
            {t("auction_details_page.quote_modal.financing.p1")}
            <b>{t("auction_details_page.quote_modal.financing.p2")}</b>
          </p>
          <ul className="px-4 py-2 list-disc">
            <li>{t("auction_details_page.quote_modal.financing.list.1")}</li>
            <li>{t("auction_details_page.quote_modal.financing.list.2")}</li>
            <li>{t("auction_details_page.quote_modal.financing.list.3")}</li>
          </ul>
        </div>
        <div className="flex flex-col justify-center items-center py-4">
          <h3 className="head font-semibold">
            {t("auction_details_page.quote_modal.financing.find_rate_heading")}
          </h3>
          <img src={Images.LightSream} alt="truck" className="w-48" />
        </div>
        <div className="py-2 flex flex-col md:flex-row gap-3">
          <div className="w-full md:w-1/2 p1">
            <label htmlFor="purchase-price">
              {t(
                "auction_details_page.quote_modal.financing.purchase_price_label"
              )}
            </label>
            <Input
              value="1200 CHF"
              name="purchase-price"
              className="w-full"
              sx={themes.JoyGrayShadeInputStyle2_2}
            />
          </div>
          <div className=" w-full md:w-w-1/2 p1">
            <label htmlFor="sellter-type">
              {t(
                "auction_details_page.quote_modal.financing.seller_type_selector.label"
              )}
            </label>
            <Autocomplete
              name="sellter-type"
              options={[
                {
                  label: "private party",
                },
                {
                  label: "dealer",
                },
              ]}
              sx={themes.JoyGrayShadeInputStyle2_2}
              className="w-3/4"
            />
          </div>
        </div>
        <div className="flex flex-col py-2 justify-center items-center gap-3 ">
          <h4 className="head">
            {t(
              "auction_details_page.quote_modal.financing.rates_starting_heading"
            )}
          </h4>
          <div className="border dark:border-[--gray] darkShadow flex flex-col rounded-lg w-full shadow-md relative">
            <div className="flex flex-col justify-center items-center gap-1 px-2 pt-12 pb-3">
              <p className="p2 text-semibold">
                {t("auction_details_page.quote_modal.financing.rate_duration")}
              </p>
              <span className="head font-black">2256 CHF</span>
              <p className="p2">
                {t(
                  "auction_details_page.quote_modal.financing.rate_duration_label"
                )}
              </p>
            </div>
            <div>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  {t("auction_details_page.quote_modal.financing.apply_butn")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <div className="border dark:border-[--gray] darkShadow flex flex-col rounded-lg w-full shadow-md relative">
            <div className="flex flex-col justify-center items-center gap-1 px-2 pt-12 pb-3">
              <p className="p2 text-semibold">
                {t("auction_details_page.quote_modal.financing.rate_duration")}
              </p>
              <span className="head font-black">2256 CHF</span>
              <p className="p2">
                {t(
                  "auction_details_page.quote_modal.financing.rate_duration_label"
                )}
              </p>
            </div>
            <div>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  {t("auction_details_page.quote_modal.financing.apply_butn")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <div className="border dark:border-[--gray] darkShadow flex flex-col rounded-lg w-full shadow-md relative">
            <div className="flex flex-col justify-center items-center gap-1 px-2 pt-12 pb-3">
              <p className="p2 text-semibold">
                {t("auction_details_page.quote_modal.financing.rate_duration")}
              </p>
              <span className="head font-black">2256 CHF</span>
              <p className="p2">
                {t(
                  "auction_details_page.quote_modal.financing.rate_duration_label"
                )}
              </p>
            </div>
            <div>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  {t("auction_details_page.quote_modal.financing.apply_butn")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <div className="border dark:border-[--gray] darkShadow flex flex-col rounded-lg w-full shadow-md relative">
            <div className="flex flex-col justify-center items-center gap-1 px-2 pt-12 pb-3">
              <p className="p2 text-semibold">
                {t("auction_details_page.quote_modal.financing.rate_duration")}
              </p>
              <span className="head font-black">2256 CHF</span>
              <p className="p2">
                {t(
                  "auction_details_page.quote_modal.financing.rate_duration_label"
                )}
              </p>
            </div>
            <div>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                  {t("auction_details_page.quote_modal.financing.apply_butn")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>
        <div className="py-6 border-b-2  dark:border-[--gray]">
          <div className="text-center pb-3">
            <Link to="/" className="mx-auto underline p1">
              {t(
                "auction_details_page.quote_modal.financing.different_rate_link"
              )}
            </Link>
          </div>
          <div className="p2	flex flex-col justify-start items-center gap-2 text-[var(--gray-color)] py-4">
            <p>{t("auction_details_page.quote_modal.financing.p3")}</p>
            <p>{t("auction_details_page.quote_modal.financing.p4")}</p>
            <p>{t("auction_details_page.quote_modal.financing.p5")}</p>
          </div>
        </div>
        <div className="py-6">
          <h3 className="head text-center font-black tracking-wide">
            {t(
              "auction_details_page.quote_modal.financing.payment_calculator_heading"
            )}
          </h3>
          <p className="p00 text-center pt-1">
            <span className="font-bolder">2240 CHF</span>{" "}
            {t(
              "auction_details_page.quote_modal.financing.price_duration_label"
            )}
          </p>
          <div className="flex flex-col justify-center items-center gap-3 py-4 w-full">
            <div className="flex flex-col md:flex-row justify-between items-center gap-3 w-full">
              <div className="w-full md:w-1/2 p1">
                <label htmlFor="price">
                  {t(
                    "auction_details_page.quote_modal.financing.purchase_price_label"
                  )}
                </label>
                <Input
                  value="701200 CHF"
                  name="price"
                  className="w-full"
                  sx={themes.JoyGrayShadeInputStyle2_2}
                />
              </div>
              <div className=" w-full md:w-w-1/2 m-0 p-0 p1">
                <label htmlFor="terms">
                  {t(
                    "auction_details_page.quote_modal.financing.duration_selector_label"
                  )}
                </label>
                <Autocomplete
                  name="terms"
                  options={[
                    {
                      label: 36,
                    },
                    {
                      label: 60,
                    },
                    {
                      label: 72,
                    },
                    {
                      label: 84,
                    },
                  ]}
                  placeholder="Select months"
                  sx={themes.JoyGrayShadeInputStyle2_2}
                  className="w-2/3"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center gap-3 w-full">
              <div className="w-full md:w-1/2 p1">
                <label htmlFor="down-payment">
                  {t(
                    "auction_details_page.quote_modal.financing.down_payment_label"
                  )}
                </label>
                <Input
                  value="0.00 CHF"
                  name="down-payment"
                  fullWidth
                  sx={themes.JoyGrayShadeInputStyle2_2}
                />
              </div>
              <div className="w-full md:w-1/2 p1">
                <label htmlFor="interest-rate">
                  {t(
                    "auction_details_page.quote_modal.financing.interest_rate_label"
                  )}
                </label>
                <Input
                  value="8.99%"
                  name="interest-rate"
                  fullWidth
                  sx={themes.JoyGrayShadeInputStyle2_2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Financing;
