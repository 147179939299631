import { useEffect, useState, memo, useContext } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Input } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { PasswordInputField, WarningAlert } from "../../components";
import ForgotPassword from "./ForgotPassword.component.page";
import { Images } from "../../assets";
import FacebookLogin from "react-facebook-login";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import { ThemeContext } from "../../context/theme.context";

type typeOfForm = String;
type Boolean = boolean;

const Login = memo(function Login({
  openLogin,
  setOpenLogin,
  onlyCloseOnSubmit,
}: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const themes = Utils.Themes();
  const { theme } = useContext(ThemeContext);
  const forms = ["Log in", "sign up", "forgot password"];
  const [formType, setFormType] = useState<typeOfForm>(forms[0]);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [googleAuthUrl, setGoogleAuthUrl] = useState<string>("");

  const formik = useFormik({
    initialValues: { email: "", username: "", password: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email()
        .required(t("login_modal.validation.email")),
      username:
        formType === forms[1]
          ? Yup.string()
              .trim()
              .required(t("login_modal.validation.username.required_message"))
              .min(6, t("login_modal.validation.username.min_length_message"))
          : Yup.string(),
      password:
        formType === forms[1]
          ? Yup.string()
              .trim()
              .required(t("login_modal.validation.password.required_message"))
              .min(6, t("login_modal.validation.password.min_length_message"))
              .max(20, t("login_modal.validation.password.max_length_message"))
              .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\])[A-Za-z\d@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\]{6,20}$/,
                t("login_modal.validation.password.match_message")
              )
          : Yup.string()
              .trim()
              .required(t("login_modal.validation.password.required_message"))
              .min(6, t("login_modal.validation.password.min_length_message")),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const handleLogin = async () => {
    setIsLoading(true);
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    await Services.Auth.SignIn(data)
      .then(async (res) => {
        setOpenLogin && setOpenLogin(false);
        if (res.status) {
          await Services.Profile.GetProfileUsingAT(res.data.access_token)
            .then(async (response) => {
              setIsLoading(false);
              if (response.status) {
                resetForm();
                const storeinfo = await Services.Storage.setSession(
                  res.data.access_token,
                  response.data
                );
                if (storeinfo) {
                  Services.Storage.setUserRole("user");
                  navigate(location?.pathname || "/", { replace: true });
                  return window.location.reload();
                }
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setOpenLogin && setOpenLogin(false);
        setIsLoading(false);
        resetForm();
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
      });
  };

  const handleSignup = async () => {
    setIsLoading(true);
    const data = {
      username: userName.trim(),
      email: email.trim(),
      password: password.trim(),
    };
    Services.Auth.SignUp(data)
      .then((res) => {
        resetForm();
        setFormType(forms[0]);
        setOpenLogin && setOpenLogin(false);
        setIsLoading(false);
        Swal.fire({
          icon: res.status ? "success" : "error",
          title: res.message,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setOpenLogin && setOpenLogin(false);
        resetForm();
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
      });
  };

  const handleSubmit = (e?: any) => {
    if (formType === forms[0]) {
      handleLogin();
    } else if (formType === forms[1]) {
      handleSignup();
    }
  };

  const resetForm = () => {
    setUserName("");
    setEmail("");
    setPassword("");
    setIsLoading(false);
    formik?.resetForm();
  };

  const handleFormType = (formType: string) => {
    setFormType(formType);
    resetForm();
  };

  const handleGoogleAuth = () => {
    Services.Auth.GoogleSignIn()
      .then((res) => {
        res.status &&
          res.data &&
          res.data.url &&
          setGoogleAuthUrl(res.data.url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    !Services.Auth.IsUserLogedIn() && handleGoogleAuth();
  }, []);

  const responseFacebook = (response: any) => {
    setIsLoading(true);
    const accessToken = response.accessToken;
    const data = {
      token: accessToken,
    };
    if (accessToken) {
      Services.Auth.FacebookSignIn(data)
        .then(async (res) => {
          setOpenLogin && setOpenLogin(false);
          if (res.status) {
            await Services.Profile.GetProfileUsingAT(res.data.access_token)
              .then(async (response) => {
                setIsLoading(false);
                if (response.status) {
                  const storeinfo = await Services.Storage.setSession(
                    res.data.access_token,
                    response.data
                  );
                  if (storeinfo) {
                    Services.Storage.setUserRole("user");
                    navigate("/", { replace: true });
                    return window.location.reload();
                  }
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          setOpenLogin && setOpenLogin(false);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t("login_modal.sweat_alert.account_not_logged_in_message"),
          });
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openLogin}
          hideBackdrop={true}
          onClose={() => {
            setOpenLogin && setOpenLogin(false);
            setFormType(forms[0]);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            sx={themes.JoyModalSheetStyle_2}
          >
            {!onlyCloseOnSubmit && setOpenLogin && (
              <ModalClose variant="outlined"  size="sm" sx={themes.JoyModalCloseStyle_2} />
            )}
            <div className="flex justify-center align-items-center w-100">
              <h4 className="head capitalize dark:text-white">
                <b>
                  {formType === forms[0]
                    ? t("login_modal.signin_text")
                    : t("login_modal.signup_text")}
                </b>
              </h4>
            </div>
            <hr className="hrDarkMode" />
            <form
              onSubmit={formik?.handleSubmit}
              noValidate
              autoComplete={"off"}
              className="modalForm"
            >
              <div className="w-100">
                <div className="py-2">
                  <div className="flex justify-between items-center gap-2">
                    <a className="py-1 sm:py-1.5 w-full" href={googleAuthUrl}>
                      <ThemeProvider theme={themes.GoogleButtonTheme_2}>
                        <Button variant="contained" fullWidth type="button">
                          <div className="flex gap-2 ">
                            <img src={Images.GoogleLogo} alt="google logo" />
                          </div>
                        </Button>
                      </ThemeProvider>
                    </a>
                    <div className="py-1 sm:py-1.5 pt-2 w-full">
                      <FacebookLogin
                        appId="830601432061578"
                        cssClass="normal-case rounded-md border-[--blue-color] py-[6px] px-4 w-full flex justify-center items-center gap-2 shadow-md hover:shadow-lg bg-[var(--blue-color)] text-white drop-shadow-xl focus:outline-none focus:shadow-outline transition duration-900 p1 rounded-[4px] "
                        callback={responseFacebook}
                        icon={
                          <FacebookIcon
                            sx={{
                              width: "28px",
                              height: "28px",
                            }}
                          />
                        }
                        textButton=""
                      />
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="flex gap-2 justify-center items-center">
                      <hr className="w-full hrDarkMode" />{" "}
                      <p className="opacity-50 p1 dark:text-white dark:opacity-100">{t("login_modal.or")}</p>{" "}
                      <hr className="w-full hrDarkMode" />
                    </div>
                  </div>
                </div>
                {formType === forms[1] && (
                  <div className="py-1 sm:py-1.5">
                    <label htmlFor="username" className="pb-2 p1 inputLabel">
                      {t("login_modal.forms.username_label")}
                      <sup className="requireField">*</sup>
                    </label>
                    <Input
                      name="username"
                      id="username"
                      variant="outlined"
                      value={formik?.values?.username.trim()}
                      onChange={(e) => {
                        formik?.handleChange(e);
                        setUserName(e.target.value?.trim());
                      }}
                      onBlur={formik?.handleBlur}
                      sx={
                        formik?.errors?.username && formik?.touched?.username
                          ? themes.JoyInputError_2
                          : themes.JoyGrayShadeInputStyle2_2
                      }
                      className="2xl:py-2"
                      slotProps={{
                        input: {
                          maxLength: 12,
                        },
                      }}
                    />
                    <WarningAlert
                      message={
                        formik?.touched?.username
                          ? formik?.errors?.username
                          : ""
                      }
                    />
                  </div>
                )}
                <div className="py-1 sm:py-2.5 inputLabel">
                  <label htmlFor="email" className="pb-2 p1 inputLabel">
                    {t("login_modal.forms.email_address_label")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="email"
                    id="email"
                    sx={
                      formik?.errors?.email && formik?.touched?.email
                        ? themes.JoyInputError_2
                        : themes.JoyGrayShadeInputStyle2_2
                    }
                    variant="outlined"
                    value={formik?.values?.email.trim()}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      setEmail(e.target.value?.trim());
                    }}
                    onBlur={formik?.handleBlur}
                    className="2xl:py-2"
                    slotProps={{
                      input: {
                        maxLength: 255,
                      },
                    }}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.email ? formik?.errors?.email : ""
                    }
                  />
                </div>
                <div className="py-1 sm:py-1.5 inputLabel">
                  <PasswordInputField
                    // fieldName is label of the field here
                    fieldName={t("login_modal.forms.password_label")}
                    setPassword={setPassword}
                    password={password}
                    required={true}
                    name="password"
                    id="password"
                    handleChange={formik?.handleChange}
                    handleBlur={formik?.handleBlur}
                    formikProps={formik}
                    maxLength={formType === forms[1] ? 20 : 30}
                  />
                  <WarningAlert
                    message={
                      formik?.touched?.password ? formik?.errors?.password : ""
                    }
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="two-xl-larger-text"
                    disabled={isLoading || formik?.isSubmitting}
                  >
                    {formType === forms[0]
                      ? t("login_modal.signin_text")
                      : t("login_modal.signup_text")}
                  </Button>
                </ThemeProvider>
                {formType === forms[0] && (
                  <div className="flex xs-justify-center justify-between items-center sm:items-start flex-wrap">
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button
                        variant="text"
                        type="button"
                        onClick={() => {
                          handleFormType(forms[1]);
                        }}
                        className="two-xl-large-text"
                      >
                        {t("login_modal.signup_text")}
                      </Button>
                      <Button
                        variant="text"
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                          setOpenForgot(!openForgot);
                          resetForm();
                        }}
                        className="two-xl-large-text"
                      >
                        {t("login_modal.buttons_text.forgot_password")}
                      </Button>
                    </ThemeProvider>
                  </div>
                )}
                {formType === forms[1] && (
                  <div className="flex justify-center items-center flex-wrap">
                    <p className="p1 inputLabel">
                      {t("login_modal.existed_account_label")}
                    </p>
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button
                        variant="text"
                        type="button"
                        onClick={() => {
                          handleFormType(forms[0]);
                        }}
                        className="two-xl-large-text"
                      >
                        {t("login_modal.signin_text")}
                      </Button>
                    </ThemeProvider>
                  </div>
                )}
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
      {openForgot && (
        <ForgotPassword
          open={openForgot}
          setOpen={setOpenForgot}
          forgotFormType="forgot"
          setOpenLogin={setOpenLogin}
        />
      )}
    </>
  );
});

export default Login;
