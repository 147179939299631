import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import PageComponents from "../pages/components";
import { Box, Button, Drawer, ThemeProvider } from "@mui/material";
import Utils from "../utils";
import { Sort } from "@mui/icons-material";
import { useScreen } from "../hooks";

interface AuctionProps {
  sort: string | null;
  setSort: any; // (sort: string | null) => void
  startYear?: number | null;
  setStartYear?: any; // (startYear: number | null) => void
  endYear?: number | null;
  setEndYear?: any; // (endYear: number | null) => void
  setTransmission?: any; // (transmission: string | null) => void
  setBodyStyle?: any; // (bodyStyle: string | null) => void
  listingsType: string;
  setModel?: any; // (model: string | null) => void
  setMake?: any; // (make: string | null) => void
  hideFilter?: boolean;
}

const AuctionNavComponent = memo(function AuctionNavComponent({
  setSort,
  sort,
  setStartYear,
  setEndYear,
  setTransmission,
  setBodyStyle,
  startYear,
  endYear,
  listingsType,
  setModel,
  setMake,
  hideFilter,
}: AuctionProps) {
  const { t } = useTranslation();
  const { width } = useScreen();
  const theme = Utils.Themes();
  const auctionSort = [
    {
      label: t("sort_options.ending_soon"),
      value: "ending_soon",
    },
    {
      label: t("sort_options.newly_listed"),
      value: "newly_listed",
    },
    {
      label: t("sort_options.no_reserve"),
      value: "no_reserve",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
  ];
  const resultSort = [
    {
      label: t("sort_options.recent_ended"),
      value: "recently_ended",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
    {
      label: t("sort_options.high_mile"),
      value: "highest_mileage",
    },
    {
      label: t("sort_options.low_price"),
      value: "lowest_price",
    },
    {
      label: t("sort_options.high_price"),
      value: "higest_price",
    },
  ];
  const [sortingOptions, setSortingOptions] = useState(auctionSort);
  const [filters, setFilters] = useState({
    year: {
      open: false,
      value: [0, 0],
    },
    transmation: {
      open: false,
      value: "",
    },
    bodyStyle: {
      open: false,
      value: "",
    },
    make: {
      open: false,
      value: "",
    },
    model: {
      open: false,
      value: "",
    },
  });
  const [openSort, setOpenSort] = useState(false);
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenSort(newOpen);
  };

  useEffect(() => {
    listingsType &&
      setSortingOptions(listingsType === "auctions" ? auctionSort : resultSort);
  }, [listingsType]);

  const applyFilters = () => {
    if (filters.year.open) {
      setStartYear && setStartYear(filters.year.value[0]);
      setEndYear && setEndYear(filters.year.value[1]);
    } else {
      setStartYear && setStartYear(null);
      setEndYear && setEndYear(null);
    }
    if (filters.transmation.open) {
      setTransmission && setTransmission(filters.transmation.value);
    } else {
      setTransmission && setTransmission(null);
    }
    if (filters.bodyStyle.open) {
      setBodyStyle && setBodyStyle(filters.bodyStyle.value);
    } else {
      setBodyStyle && setBodyStyle(null);
    }
    if (filters.make.open) {
      setMake && setMake(filters.make.value);
    } else {
      setMake && setMake(null);
    }
    if (filters.model.open) {
      setModel && setModel(filters.model.value);
    } else {
      setModel && setModel(null);
    }
  };

  const SortOptions = () => {
    return (
      <nav className="sortStyle  ">
        {sortingOptions?.length > 0 &&
          sortingOptions?.map((item, index) => (
            <button
              className={`main-outline-button-rounded ${
                sort === item?.value
                  ? "bg-[var(--main-color)] text-white"
                  : "text-black dark:text-[--light]"
              }`}
              onClick={() => setSort(item?.value)}
              key={index}
            >
              {item?.label}
            </button>
          ))}
      </nav>
    );
  };

  return (
    <section className="flex justify-between items-center flex-wrap gap-1 sm:gap-2 sx-center">
      <div className="">
        <div
          className={listingsType === "auctions" ? "xl:hidden" : "2xl:hidden"}
        >
          <ThemeProvider theme={theme.ButtonActiveTheme_2}>
            <Button
              variant="contained"
              onClick={toggleDrawer(true)}
              sx={{ textTransform: "capitalize", minWidth: "max-content" }}
            >
              <Sort className="me-1 sm:me-2" /> {t("sort_butn_text")}
            </Button>
          </ThemeProvider>
        </div>
        <div
          className={`hidden ${listingsType === "auctions" ? "xl:flex" : "2xl:flex"}`}
        >
          <SortOptions />
        </div>
        <ThemeProvider theme={theme.MuiDrawerTheme}>
          <Drawer
            open={openSort}
            onClose={toggleDrawer(false)}
            className={listingsType === "auctions" ? "xl:hidden" : "2xl:hidden"}
          >
            <Box
              sx={{
                width: width <= 768 ? 280 : 360,
              }}
              role="presentation"
            >
              <div className="flex flex-col gap-4 py-12 px-6">
                <div className="flex gap-1 justify-start items-center dark:text-white">
                  <Sort /> <h4 className="head">{t("sort_title")}</h4>
                </div>
                <div className="pt-5">
                  <SortOptions />
                </div>
              </div>
            </Box>
          </Drawer>
        </ThemeProvider>
      </div>
      {!hideFilter && (
        <div className="flex-1 flex justify-end sx-center">
          <PageComponents.FiltersComponent
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
          />
        </div>
      )}
    </section>
  );
});

export default AuctionNavComponent;
