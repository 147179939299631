import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import moment from "moment";
import Countdown from "react-countdown";
import PageComponents from "../components";
import Swal from "sweetalert2";
import { Popover as HeadlessUiPopover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";
import Theme from "../../utils/Theme.utils";
import { ConvertToLocal } from "../../utils/CalculateDays";

const Auction = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loadingdata, setLoadingdata] = useState<boolean>(true);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [sort, setSort] = useState<string>("");
  const [reserveStatus, setReserveStatus] = useState<string>("");
  const [auctionStatus, setAuctionStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [search_text, setSearchText] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const handleAuction = (e?: any) => {
    e?.preventDefault();
    Services.Auction.GetAuctions(
      offset,
      limit,
      search_text?.toLocaleLowerCase(),
      sort,
      reserveStatus,
      auctionStatus
    )
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          res.data && setAuctions(res.data);
          res.total && setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && handleAuction();
  }, [offset, limit, sort, reserveStatus, auctionStatus, search_text]);

  const resetPagenation = () => {
    setOffset(0);
    setTotal(0);
    setPage(1);
  };

  const handleSearch = (e: any) => {
    e?.preventDefault();
    resetPagenation();
    setSort("");
    setReserveStatus("");
    setAuctionStatus("");
    setSearchText(search);
  };

  const handleRemainingDays = (date: string) => {
    // return (
    //   (new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
    // );
    const now = moment().local(); // Current local time
    const targetDate = moment(date).local(); // The target date

    // Calculate the difference in days
    const diffInDays = targetDate.diff(now, "days", true); // 'true' keeps decimal precision

    return diffInDays;
  };

  const handleSubmit = (list: any) => {
    Swal.fire({
      icon: "warning",
      title: t("sw_alert_confirm_title"),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: t("facts_info.yes_text"),
      denyButtonText: t("facts_info.no_text"),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoadingdata(true);
        const data = {
          id: list?.uuid ? [list.uuid] : [],
          status: "sold",
        };
        Services.Auction.ChangeAuctionStatus(data)
          .then((res) => {
            setLoadingdata(false);
            if (res.status) {
              handleAuction();
              Swal.fire(
                t("auction_page.c1kaucuwn"),
                t("auction_page.xottovs1t")
              );
            } else {
              Swal.fire(t("auction_page.error"), res.message);
            }
          })
          .catch((error) => {
            setLoadingdata(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
              });
            }
          });
      }
    });
  };

  const resetFilter = () => {
    setSort("");
    setAuctionStatus("");
    setReserveStatus("");
  };

  const resetSort = () => {
    setSort("");
  };

  const desktopCellStyle = {
    minWidth: "max-content",
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("auction_page.lq46o04u8")} />
      <div className=" ">
        <div className=" ">
          {/* list items */}
          <div className="flex justify-between items-center py-6 flex-wrap gap-2">
            <div className="flex flex-col justify-center items-start">
              <h3 className="head leading-6 font-bold text-gray-900">
                {t("auction_page.24ff7ovgo")}
              </h3>
              <div role="presentation">
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("auction_page.s5gmvni0o")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("auction_page.24ff7ovgo")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <div className="grow" />
            <div className="m-auto">
              <HeadlessUiPopover className="relative">
                {({ open }) => (
                  <>
                    <HeadlessUiPopover.Button
                      className={`${open ? "text-black" : "text-black/90"}
               headlessUIButton `}
                    >
                      <span className="p1 dark:text-white">
                        {t("auction_page.b1nfkcfba")}
                      </span>
                      <ChevronDownIcon
                        className={`text-[var(--main-color)] ml-2 h-5 w-5 transition duration-150 ease-in-out ${
                          open ? "rotate-180" : ""
                        }	`}
                        aria-hidden="true"
                      />
                    </HeadlessUiPopover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <HeadlessUiPopover.Panel className="headlessUiPopoverPanel min-w-[280px]">
                        <div className="headlessUiPopoverPanelDiv">
                          <div className="px-6 py-6">
                            <h3 className="head font-bolder text-center capitalize pb-2">
                              {t("auction_page.n4r8w3e30")}
                            </h3>
                            <div className="flex flex-col gap-1 py-1">
                              <ThemeProvider
                                theme={
                                  sort === "higest_price"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() => {
                                    setSort(
                                      sort === "higest_price"
                                        ? ""
                                        : "higest_price"
                                    );
                                    setOffset(0);
                                    setPage(1);
                                  }}
                                  sx={{
                                    textAlign: "left",
                                    marginRight: "auto",
                                  }}
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.bwmdvl3jt")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  sort === "recently_ended"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() => {
                                    setSort(
                                      sort === "recently_ended"
                                        ? ""
                                        : "recently_ended"
                                    );
                                    setOffset(0);
                                    setPage(1);
                                  }}
                                  size="small"
                                  sx={{
                                    textAlign: "left",
                                    marginRight: "auto",
                                  }}
                                >
                                  <p className="p1">
                                    {t("auction_page.evy8qg2ok")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                            </div>
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                onClick={() => {
                                  resetSort();
                                  setOffset(0);
                                  setPage(1);
                                }}
                                fullWidth
                                size="small"
                                variant="contained"
                              >
                                {t("auction_page.y7przu2yz")}
                              </Button>
                            </ThemeProvider>
                          </div>
                        </div>
                      </HeadlessUiPopover.Panel>
                    </Transition>
                  </>
                )}
              </HeadlessUiPopover>
            </div>
            <div className="m-auto">
              <HeadlessUiPopover className="relative">
                {({ open }) => (
                  <>
                    <HeadlessUiPopover.Button
                      className={`${
                        open ? "text-[var(--main-color)]" : "text-black"
                      }
                headlessUIButton`}
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </HeadlessUiPopover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <HeadlessUiPopover.Panel className="headlessUiPopoverPanel min-w-[300px] 2xl:min-w-[280px]">
                        <div className="headlessUiPopoverPanelDiv">
                          <div className="px-6 py-6">
                            <h3 className="head font-bold text-center capitalize pb-2">
                              {t("auction_page.31h24s55e")}
                            </h3>
                            <div className="pb-1">
                              <span className="headlessUiPopoverTitle2">
                                {t("auction_page.tthu75fb2")}
                              </span>
                              <ThemeProvider
                                theme={
                                  reserveStatus === "reserve"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() =>
                                    setReserveStatus(
                                      reserveStatus === "reserve"
                                        ? ""
                                        : "reserve"
                                    )
                                  }
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.wzlakih6r")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  reserveStatus === "no_reserve"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() =>
                                    setReserveStatus(
                                      reserveStatus === "no_reserve"
                                        ? ""
                                        : "no_reserve"
                                    )
                                  }
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.2ht5bb5jv")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div className="pb-1">
                              <span className="headlessUiPopoverTitle2">
                                {t("auction_page.mwt3prz8z")}
                              </span>
                              <ThemeProvider
                                theme={
                                  auctionStatus === "live"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() =>
                                    setAuctionStatus(
                                      auctionStatus === "live" ? "" : "live"
                                    )
                                  }
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.wgggfm9rg")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  auctionStatus === "sold"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() =>
                                    setAuctionStatus(
                                      auctionStatus === "sold" ? "" : "sold"
                                    )
                                  }
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.wsocd2o8q")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  auctionStatus === "past"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  onClick={() =>
                                    setAuctionStatus(
                                      auctionStatus === "past" ? "" : "past"
                                    )
                                  }
                                  size="small"
                                >
                                  <p className="p1">
                                    {t("auction_page.ddv5f3guj")}
                                  </p>
                                </Button>
                              </ThemeProvider>
                            </div>
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                onClick={() => resetFilter()}
                                fullWidth
                                size="small"
                                variant="contained"
                              >
                                {t("auction_page.gjk3ap0c2")}
                              </Button>
                            </ThemeProvider>
                          </div>
                        </div>
                      </HeadlessUiPopover.Panel>
                    </Transition>
                  </>
                )}
              </HeadlessUiPopover>
            </div>
            <form onSubmit={(e) => handleSearch(e)} className="w-full sm:w-fit">
              <PageComponents.SearchComponent
                search={search}
                setSearch={setSearch}
                classes="w-full sm:w-fit"
              />
            </form>
            <div className="w-full sm:w-fit">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/create-auction", {
                      state: { formType: "Create" },
                    })
                  }
                  fullWidth
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="CurrentColor"
                    viewBox="0 0 448 512"
                    className="w-4 h-4 mr-2"
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                  {t("auction_page.24ff7ovgo")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* desktop responsive table */}
          <div className="">
            {!loadingdata && (
              <div className="hidden md:block w-full overflow-x-auto">
                <Box
                  component={Paper}
                  sx={themes.MuiTableBox}
                  className="overflow-x-auto "
                >
                  <ThemeProvider theme={themes.TableTheme_2}>
                    <TableContainer className="muiTableContainer">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {t("listing_dashboard_page.riensgads")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.tdtm9bgvg")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.8rw0xqkot")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.z3y8kkevx")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.ascsz9fwg")}
                            </TableCell>
                            <TableCell align="center">
                              {t("auction_page.u0lfcpylz")}
                            </TableCell>
                            <TableCell align="center">
                              # {t("auction_page.vcxodau60")}
                            </TableCell>
                            <TableCell>{t("auction_page.ck4tunyoy")}</TableCell>
                            <TableCell>{t("auction_page.cok4tuyoy")}</TableCell>
                            <TableCell align="center">
                              # {t("auction_page.0j931p98s")}
                            </TableCell>
                            <TableCell align="center">
                              # {t("auction_page.x04l9cqdn")}
                            </TableCell>
                            <TableCell>{t("auction_page.mwt3prz8z")}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {auctions?.length > 0 &&
                            auctions?.map((list: any, index: number) => (
                              <TableRow
                                key={list.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={desktopCellStyle}
                                >
                                  {list?.title ? list?.title : ""}
                                </TableCell>
                                <TableCell align="center" sx={desktopCellStyle}>
                                  {list.year}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  {list.make}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  {list.model}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  {list.transmission}
                                </TableCell>
                                <TableCell align="center" sx={desktopCellStyle}>
                                  {list?.current_bid?.price
                                    ? list?.current_bid?.price
                                    : 0}{" "}
                                  CHF
                                </TableCell>
                                <TableCell align="center" sx={desktopCellStyle}>
                                  {list?.stats?.bids}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  {list?.stats?.auction_start &&
                                    moment(list?.stats?.auction_start)
                                      .local()
                                      .format("DD MM YYYY")}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  {/* if auction has not started then show message auction not started*/}
                                  {list?.stats?.auction_start &&
                                  new Date(
                                    list?.stats?.auction_start
                                  ).toLocaleString() >
                                    new Date().toLocaleString()
                                    ? t("auction_page.ctunvjosd")
                                    : list?.stats?.auction_end &&
                                      (handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) > 0 &&
                                      handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) < 1 ? (
                                        <Countdown
                                          date={ConvertToLocal(
                                            list?.stats?.auction_end
                                          )}
                                          daysInHours={true}
                                        />
                                      ) : (
                                        moment(list?.stats?.auction_end)
                                          .local()
                                          .fromNow()
                                      ))}
                                </TableCell>
                                <TableCell align="center" sx={desktopCellStyle}>
                                  {list?.stats?.comments}
                                </TableCell>
                                <TableCell align="center" sx={desktopCellStyle}>
                                  {list?.stats?.questions}
                                </TableCell>
                                <TableCell sx={desktopCellStyle}>
                                  <ThemeProvider
                                    theme={themes.AppleButtonTheme_2}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        list?.stats?.auction_end &&
                                        handleRemainingDays(
                                          list?.stats?.auction_end
                                        ) <= 0
                                          ? handleSubmit(list)
                                          : navigate("/update-auction", {
                                              state: {
                                                list: list,
                                                formType: "update",
                                              },
                                            });
                                      }}
                                      fullWidth
                                      disabled={
                                        (list.status === "Past" &&
                                          (!list?.current_bid ||
                                            handleRemainingDays(
                                              list?.stats?.auction_end
                                            ) >= 0)) ||
                                        list.status === "Live" ||
                                        list.status === "Sold"
                                      }
                                      sx={{
                                        minWidth: "max-content",
                                      }}
                                    >
                                      {(list.status === "Past" &&
                                        list?.current_bid &&
                                        handleRemainingDays(
                                          list?.stats?.auction_end
                                        ) <= 0) ||
                                      list.status?.toLowerCase() === "unsold"
                                        ? t("auction_page.zzmtpff5w")
                                        : list.status}
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>
                                <TableCell align="right" className="relative">
                                  <IconButton
                                    onClick={(e) => {
                                      handleOpen(e);
                                      setSelectedId(list?.listing?.uuid);
                                    }}
                                    aria-describedby={`actionMenu-${index}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 512"
                                      className="popover"
                                    >
                                      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                    </svg>
                                  </IconButton>
                                  <Popover
                                    id={`actionMenu-${index}`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    // sx={{
                                    //   border: "none",
                                    //   boxShadow: "none",
                                    //   ".MuiPaper-root": {
                                    //     boxShadow:
                                    //       "inset 0px 6px 12px -4px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                                    //   },
                                    // }}
                                  >
                                    <div
                                      className={"MuiPopoverMenu"}
                                      id={`actionMenu-${index}`}
                                    >
                                      <ThemeProvider
                                        theme={themes.AppleButtonTheme_2}
                                      >
                                        <Button
                                          // color="inherit"
                                          fullWidth
                                          onClick={() =>
                                            list?.listing?.uuid &&
                                            navigate(
                                              `/auction-details/${selectedId}`,
                                              {
                                                state: {
                                                  listing_id: selectedId,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <div className="flex justify-start items-center mr-auto pl-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-3 h-3 "
                                              fill="currentColor"
                                              viewBox="0 0 576 512"
                                            >
                                              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                            </svg>
                                            <span className="pl-4 capitalize ">
                                              {t("auction_page.zxv1pa3a5")}
                                            </span>
                                          </div>
                                        </Button>
                                      </ThemeProvider>
                                    </div>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
          {/* mobile responsive data table */}
          <div className="py-3">
            <div className="flex flex-wrap h-full md:hidden">
              {!loadingdata &&
                auctions?.length > 0 &&
                auctions?.map((list: any, index: number) => (
                  <div className=" p-1 sm:p-2 w-full" key={index} id={list.id}>
                    <ThemeProvider theme={themes.MuiAccordionTheme}>
                      <Accordion className="accordionShadow">
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{ width: "100%", flexShrink: 0 }}
                            className="capitalize"
                          >
                            <p className="p0 font-medium capitalize">
                              {list?.title ? list?.title : ""}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <Table>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("listing_dashboard_page.riensgads")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list?.title ? list?.title : ""}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("listing_dashboard_page.tdtm9bgvg")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.year}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("listing_dashboard_page.8rw0xqkot")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.make}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("listing_dashboard_page.z3y8kkevx")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.model}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("listing_dashboard_page.ascsz9fwg")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.transmission}</TableCell>
                              </TableRow>

                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("auction_page.bwmdvl3jt")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list?.current_bid?.price
                                    ? list?.current_bid?.price
                                    : 0}{" "}
                                  CHF
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    # {t("auction_page.vcxodau60")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list?.stats?.bids}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("auction_page.ck4tunyoy")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list?.stats?.auction_start &&
                                    moment(list?.stats?.auction_start)
                                      .local()
                                      .format("DD MM YYYY")}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    {t("auction_page.cok4tuyoy")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {/* if auction has not started then show message auction not started*/}
                                  {list?.stats?.auction_start &&
                                  new Date(
                                    list?.stats?.auction_start
                                  ).toLocaleString() >
                                    new Date().toLocaleString()
                                    ? t("auction_page.ctunvjosd")
                                    : list?.stats?.auction_end &&
                                      (handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) > 0 &&
                                      handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) < 1 ? (
                                        <Countdown
                                          date={ConvertToLocal(
                                            list?.stats?.auction_end
                                          )}
                                          daysInHours={true}
                                        />
                                      ) : (
                                        moment(list?.stats?.auction_end)
                                          .local()
                                          .fromNow()
                                      ))}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    # {t("auction_page.0j931p98s")}
                                  </TableCell>
                                </TableHead>
                                <TableCell> {list?.stats?.bids}</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <TableHead>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                    }}
                                  >
                                    # {t("auction_page.x04l9cqdn")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>300</TableCell>
                              </TableRow>
                            </Table>
                            <div className="py-4 w-full flex">
                              <ThemeProvider theme={themes.AppleButtonTheme_2}>
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="action button"
                                  sx={{ margin: "auto" }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      list?.stats?.auction_end &&
                                      handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) <= 0
                                        ? handleSubmit(list)
                                        : navigate("/update-auction", {
                                            state: {
                                              list: list,
                                              formType: "update",
                                            },
                                          });
                                    }}
                                    fullWidth
                                    disabled={
                                      (list.status === "Past" &&
                                        (!list?.current_bid ||
                                          handleRemainingDays(
                                            list?.stats?.auction_end
                                          ) >= 0)) ||
                                      list.status === "Live" ||
                                      list.status === "Sold"
                                    }
                                  >
                                    {(list.status === "Past" &&
                                      list?.current_bid &&
                                      handleRemainingDays(
                                        list?.stats?.auction_end
                                      ) <= 0) ||
                                    list.status?.toLowerCase() === "unsold"
                                      ? t("auction_page.zzmtpff5w")
                                      : list.status}
                                  </Button>
                                </ButtonGroup>
                              </ThemeProvider>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </ThemeProvider>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Auction;
