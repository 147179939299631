import { memo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

type dashboardCardType = {
  counter: string;
  carHeading: string;
  lastWeekComparison: string;
  chart: any;
};

const DashboardCard = memo(
  ({ counter, carHeading, lastWeekComparison, chart }: dashboardCardType) => {
    const { t } = useTranslation();

    const chartData: any = {
      options: {
        chart: {
          id: "line-bar",
          toolbar: {
            show: false,
          },
          selection: {
            enabled: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        xaxis: {
          categories: chart.x_axis,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["var(--dark-color)"],
        fill: {
          colors: ["var(--dark-color)"],
        },
      },
      series: [
        {
          name: t("dashboard_page.91smyxlf5"),
          data: chart.y_axis,
        },
      ],
    };

    return (
      <div className="DashboardCard ">
        <div className="flex flex-col gap-4 tracking-wide	">
          <p className="p00 font-bold">{carHeading}</p>
          <span className="head8 font-extrabold tracking-wider">
            {counter}
          </span>
          <div className="flex p1 gap-1 text-center justify-start">
            <div
              className={` p-0.5 rounded-full w-5 h-5 ${
                lastWeekComparison[1] === "0" && lastWeekComparison[2] !== "."
                  ? "bg-[var(--warning-background)] text-[var(--warning-color)]"
                  : lastWeekComparison[0] === "+"
                    ? "bg-[var(--success-background)] text-[var(--success-color)]"
                    : lastWeekComparison[0] === "-"
                      ? "bg-[var(--danger-background)] text-[var(--danger-color)]"
                      : "bg-[var(--warning-background)] text-[var(--warning-color)]"
              }`}
            >
              {lastWeekComparison[1] === "0" &&
              lastWeekComparison[2] !== "." ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  className="w-full h-full"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <g id="iconifyReact507">
                    <g id="iconifyReact508">
                      <path
                        id="iconifyReact509"
                        fill="currentColor"
                        d="M21 7a.78.78 0 0 0 0-.21a.64.64 0 0 0-.05-.17a1.1 1.1 0 0 0-.09-.14a.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71l-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34l4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7Z"
                      />
                    </g>
                  </g>
                </svg>
              ) : lastWeekComparison[0] === "+" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  className="w-full h-full"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <g id="iconifyReact507">
                    <g id="iconifyReact508">
                      <path
                        id="iconifyReact509"
                        fill="currentColor"
                        d="M21 7a.78.78 0 0 0 0-.21a.64.64 0 0 0-.05-.17a1.1 1.1 0 0 0-.09-.14a.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71l-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34l4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7Z"
                      />
                    </g>
                  </g>
                </svg>
              ) : lastWeekComparison[0] === "-" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  className="w-full h-full"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <g id="iconifyReact1340">
                    <g id="iconifyReact1341">
                      <path
                        id="iconifyReact1342"
                        fill="currentColor"
                        d="M21 12a1 1 0 0 0-2 0v2.3l-4.24-5a1 1 0 0 0-1.27-.21L9.22 11.7L4.77 6.36a1 1 0 1 0-1.54 1.28l5 6a1 1 0 0 0 1.28.22l4.28-2.57l4 4.71H15a1 1 0 0 0 0 2h5a1.1 1.1 0 0 0 .36-.07l.14-.08a1.19 1.19 0 0 0 .15-.09a.75.75 0 0 0 .14-.17a1.1 1.1 0 0 0 .09-.14a.64.64 0 0 0 .05-.17A.78.78 0 0 0 21 17Z"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  className="w-full h-full"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <g id="iconifyReact507">
                    <g id="iconifyReact508">
                      <path
                        id="iconifyReact509"
                        fill="currentColor"
                        d="M21 7a.78.78 0 0 0 0-.21a.64.64 0 0 0-.05-.17a1.1 1.1 0 0 0-.09-.14a.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71l-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34l4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7Z"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </div>
            <p className="textGray p1">
              <span className="text-black font-bold dark:text-white">
                {lastWeekComparison}{" "}
              </span>
              {t('dashboard_page.rnwhlsfup')}
            </p>
          </div>
        </div>
        <div id="curveChart">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="200"
          />
        </div>
      </div>
    );
  }
);

export default DashboardCard;
