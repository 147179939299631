import React, { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Textarea } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type submitAnswer = {
  text: string;
};

const SubmitAnswer = memo(function SubmitAnswer({
  isModalOpen,
  setIsModalOpen,
  setQuestionId,
  questionId,
  getQuestions,
  question,
}: any) {
  const { t } = useTranslation();
  const params = useParams();
  const themes = Utils.Themes();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ answer: "" });
  const [answer, setAnswer] = useState<string>("");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (errors.answer !== "") {
      let emailInput = document.getElementById("answer");
      if (emailInput !== null) {
        return emailInput.focus();
      }
    } else {
      // submit the form if no error occurs
      if (params.list_id && questionId != 0) {
        const data: submitAnswer = {
          text: answer,
        };
        setIsLoading(true);
        Services.QA.SubmitAnswer(params.list_id, questionId, data)
          .then((res) => {
            setIsLoading(false);
            if (res.status) {
              Swal.fire(t("user_application_page.cg7ln796g"));
              setQuestionId(0);
              setIsModalOpen(false);
              getQuestions();
              setAnswer("");
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading && <PreLoaderComponent2 />}
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={isModalOpen}
          hideBackdrop={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet
            sx={themes.JoyModalSheetStyle_2}
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
          >
            <ModalClose
              variant="outlined"
              size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className=" w-full">
                <div className="flex flex-row">
                  <div className=" text-center w-full">
                    <div className="mx-auto my-auto flex items-center justify-center h-auto w-12 pb-3 dark:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        stroke="currentColor"
                        className="w-20 h-20 mb-3"
                        strokeWidth="1.5"
                        fill="currentColor"
                      >
                        <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                      </svg>
                    </div>
                    <h3 className="head leading-6 font-bold text-gray-900">
                      {t("user_application_page.p426cv6ju")}
                    </h3>
                    <div className="w-full py-4">
                      <p className="p1 text-start  w-full ">
                        <span className="font-bold p0">Q: </span>{" "}
                        {question.text}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-1 sm:gap-y-0 gap-x-3 w-full ">
                      <div className="InputFieldSetting">
                        <Textarea
                          placeholder={t("user_application_page.x9s4itm0y")}
                          variant="outlined"
                          required={true}
                          style={{ width: "100%" }}
                          value={answer}
                          id="answer"
                          onChange={(e) => setAnswer(e.target.value)}
                          slotProps={{
                            textarea: {
                              maxLength: 250,
                            },
                          }}
                          sx={
                            errors.answer
                              ? themes.JoyInputError_2
                              : themes.JoyGrayShadeInputStyle2_2
                          }
                        />
                      </div>
                      {window.matchMedia("(max-width: 640px)").matches && (
                        <WarningAlert message={errors.answer} />
                      )}
                      <div className="min-w-fit pt-4">
                        <ThemeProvider theme={themes.ButtonTheme_2}>
                          <Button
                            variant="contained"
                            type="submit"
                            className=""
                            fullWidth
                            disabled={answer === "" || isLoading}
                          >
                            {isLoading
                              ? t("user_application_page.ul168nlcy")
                              : t("user_application_page.6ft1588z5")}
                          </Button>
                        </ThemeProvider>
                      </div>
                    </div>
                    {window.matchMedia("(min-width: 640px)").matches && (
                      <WarningAlert message={errors.answer} />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default SubmitAnswer;
