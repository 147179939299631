import React, { memo } from "react";
import { useTranslation } from "react-i18next";

type propsType = {
  heading: string;
  html: string | any;
};
// checked
const AuctionDetail = memo(({ heading, html }: propsType) => {
  const { t } = useTranslation();
  const createMarkup = (html: any) => {
    return {
      __html: html,
    };
  };

  return (
    <>
      <div className="py-6 px-4 border-b dark:border-[--gray]">
        <h3 className="font-bold head tracking-wide pb-2">
          {heading}
        </h3>
        <div className=" ">
          {html ? (
            <div
              dangerouslySetInnerHTML={createMarkup(html)}
              className="quill overflow-wrap-anywhere text-wrap"
            />
          ) : (
            <p className="info_message">
              {t("auction_details_page.auction_details_not_found_text")}{" "}
            </p>
          )}
        </div>
      </div>
    </>
  );
});

export default AuctionDetail;
