import { lazy, memo, useState, Suspense, useContext } from "react";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";

interface VerifiedBidderType {
  show: boolean;
  setShow: any;
  getProfile?: any;
  auction_info?: any;
}

const AddCreditCard = lazy(
  () => import("../components/AddCreditCard.component")
);

const VerifiedBidderComponent = memo(
  ({ show, setShow, getProfile, auction_info }: VerifiedBidderType) => {
    const { t } = useTranslation();
    const themes = Utils.Themes();
    const { isDark } = useContext(ThemeContext);

    const [showCredit, setShowCredit] = useState<boolean>(false);
    const [hideBidModal, setHideBidModal] = useState<boolean>(true);

    return (
      <div className="">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={show}
          hideBackdrop={true}
          onClose={() => {
            setShow(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-y-auto overflow-x-hidden"
            sx={themes.JoyModalSheetStyle_2}
          >
            <ModalClose
              variant="outlined"
              size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <div className="flex flex-col justify-center items-center w-100 gap-2 ">
              <svg
                viewBox="0 0 61 29"
                fill="none"
                strokeWidth="0.1"
                stroke="currentColor"
                className="w-16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.3309 2.5H40.974C41.1857 2.5 41.3748 2.63219 41.4476 2.83098L44.4382 11H47.1005L43.7952 1.97153C43.3617 0.787413 42.2349 0 40.974 0H27.3309C25.9223 0 24.7028 0.978688 24.3978 2.35391L22.4805 11H25.0412L26.8385 2.89517C26.8897 2.6643 27.0945 2.5 27.3309 2.5Z"
                  fill={isDark ? "white" : "#292929"}
                ></path>
                <path
                  d="M25.7239 0H27.3921L25.0248 3.04476L24.248 3.08484C24.3754 2.18474 24.3581 1.92343 24.4847 1.2016C24.5541 0.805277 24.7031 0 25.7239 0Z"
                  fill={isDark ? "white" : "#292929"}
                ></path>
                <path
                  d="M57.1106 12.5H21.8894C21.6743 12.5 21.5 12.6743 21.5 12.8894V17.5L20 19.5L19 19.6667V12.8894C19 11.2936 20.2936 10 21.8894 10H57.1106C58.7064 10 60 11.2936 60 12.8894V19.6429L59 19.5L57.5 17.5V12.8894C57.5 12.6743 57.3257 12.5 57.1106 12.5Z"
                  fill={isDark ? "white" : "#292929"}
                ></path>
                <path
                  d="M48.5 23.5H30.5L29.5 25L29.4167 26H49V25L48.5 23.5Z"
                  fill={isDark ? "white" : "#292929"}
                ></path>
                <rect
                  x="46"
                  y="14"
                  width="4"
                  height="1.5"
                  rx="0.75"
                  fill={isDark ? "white" : "#292929"}
                ></rect>
                <path
                  d="M27.5647 10.0158C25.1505 10.0158 25.5529 8.60752 25.8211 7.4004L24.7481 7.19922L23.3398 10.6194H27.2148L27.5647 10.0158Z"
                  fill={isDark ? "white" : "#292929"}
                ></path>
                <circle
                  cx="54"
                  cy="22"
                  r="5.75"
                  stroke={isDark ? "white" : "#292929"}
                  strokeWidth="2.5"
                ></circle>
                <circle
                  cx="25"
                  cy="22"
                  r="5.75"
                  stroke={isDark ? "white" : "#292929"}
                  strokeWidth="2.5"
                ></circle>
                <rect
                  x="7"
                  y="12"
                  width="7"
                  height="2"
                  rx="1"
                  fill={isDark ? "white" : "#292929"}
                ></rect>
                <rect
                  y="16"
                  width="12"
                  height="2"
                  rx="1"
                  fill={isDark ? "white" : "#292929"}
                ></rect>
                <rect
                  x="5"
                  y="20"
                  width="10"
                  height="2"
                  rx="1"
                  fill={isDark ? "white" : "#292929"}
                ></rect>
              </svg>
              <h3 className="head pb-4 font-bold text-gray-900">
                {t("verified_bidder.title")}
              </h3>
            </div>
            <p className="p1 text-start w-full ">
              {t("verified_bidder.description")}
            </p>
            <div className=" w-full py-2 ">
              <div className="flex justify-center items-center gap-4 py-6">
                <ThemeProvider theme={themes.TabsStyle_2}>
                  <Button variant="outlined" onClick={() => setShow(!show)}>
                    {t("verified_bidder.cancel_butn_text")}
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    variant="contained"
                    onClick={() => setShowCredit(true)}
                  >
                    {t("verified_bidder.view_agreement_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </Sheet>
        </Modal>
        <Suspense>
          <AddCreditCard
            showCreditCard={showCredit}
            setShowCreditCard={setShowCredit}
            auction_info={auction_info}
            hideBidModal={hideBidModal}
            hideAgreementModal={() => {
              setShow(false);
              getProfile && getProfile();
            }}
          />
        </Suspense>
      </div>
    );
  }
);

export default VerifiedBidderComponent;
