import { useEffect, useState } from "react";
import JoyAutocomplete from "@mui/joy/Autocomplete";
import { ThemeProvider, Button, Skeleton } from "@mui/material";
import Utils from "../../utils";
import { AccessTime, InsertInvitation } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import moment from "moment";
import Swal from "sweetalert2";
import { PreLoaderComponent2 } from "../../components";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

const handleTime = (time: string) => {
  let timeArray = time.split(":");
  let format = "";
  timeArray.pop();
  if (time > "12:00:00") {
    let tweleveHourFormat = Number(timeArray[0]) - 12;
    let hours = "00";
    tweleveHourFormat > 10
      ? (hours = "0" + tweleveHourFormat.toString())
      : (hours = tweleveHourFormat.toString());
    timeArray[0] = hours;
    format = "PM";
  } else {
    format = "AM";
  }

  return timeArray.join(":") + " " + format;
};

const Scheduling = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const [listing, setListing] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<any>({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>({});
  const [schedules, setSchedules] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<any[]>([]);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetForms = () => {
    setSelectedDate({});
    setSelectedTimeSlot({});
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      schedule_date_time_slot_id: selectedTimeSlot.id,
    };
    if (listing.id) {
      setIsSubmitting(true);
      Services.Listing.UpdateLisitng(listing.id, data)
        .then((res) => {
          setIsSubmitting(false);
          if (res.status) {
            window.location.reload();
            Swal.fire(
              t("user_schedule_page.mcv2swrhy"),
              t("user_schedule_page.iay2mkx3j")
            );
            resetForms();
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.data.message.split(".")[0],
            });
          }
        });
    }
  };

  const getListingInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status && res.data) {
          const data = res.data;
          if (
            data.application_status !== "approved" ||
            !data.title ||
            !data.description ||
            !data.engine ||
            !data.drive_train ||
            !data.body_style ||
            !data.exterior_color ||
            !data.interior_color ||
            !data.service_history ||
            data.photos?.exterior?.length === 0 ||
            data.photos?.interior?.length === 0 ||
            data.photos?.mechanical?.length === 0
          ) {
            return navigate(`/application/${params?.list_id}`);
          }
          setListing(data);
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params]);

  const getTimeSlots = () => {
    Services.Schedule.GetTimeSlots(0, 10).then((res) => {
      res?.status && setSchedules(res.data);
    });
  };

  useEffect(() => {
    getTimeSlots();
  }, []);

  const handleDates = () => {
    let datesArray: any[] = [];
    schedules?.map((schedule) => {
      let isTimeSlotAvailable = false;
      schedule?.time_slots?.length > 0 &&
        schedule?.time_slots?.map((item: any, index: number) => {
          if (item.status == "available") {
            isTimeSlotAvailable = true;
          }
        });

      isTimeSlotAvailable &&
        datesArray.push({
          label: moment(schedule.date).format("DD MMM YYYY"),
          id: schedule.id,
          timeSlots: schedule.time_slots,
        });
    });
    setDates(datesArray);
  };

  useEffect(() => {
    schedules.length > 0 && handleDates();
  }, [schedules]);

  const changeTimeFormat = (slot_item: string) => {
    let time = Number(slot_item[0] + slot_item[1]);
    time > 12 && (time = time - 12);
    let sTime = String(time);
    return (
      (sTime.length === 1 ? "0" + sTime : sTime) +
      ":" +
      slot_item[3] +
      slot_item[4]
    );
  };

  const handleTimeSlots = () => {
    const timeSlotsArray: any[] = [];
    selectedDate?.timeSlots?.length > 0 &&
      selectedDate?.timeSlots?.map((item: any, index: number) => {
        if (item.status == "available") {
          if (selectedDate.label == moment().format("DD MMM YYYY")) {
            const current_time = moment(new Date().getTime()).format("hh:mm");
            if (
              changeTimeFormat(item.start_time) > current_time &&
              changeTimeFormat(item.end_time) > current_time
            ) {
              timeSlotsArray.push({
                label:
                  handleTime(item.start_time) +
                  " - " +
                  handleTime(item.end_time),
                id: item.id,
              });
            }
          } else {
            timeSlotsArray.push({
              label:
                handleTime(item.start_time) + " - " + handleTime(item.end_time),
              id: item.id,
            });
          }
        }
      });
    setTimeSlots(timeSlotsArray);
  };

  useEffect(() => {
    selectedDate && Object.keys(selectedDate).length > 0 && handleTimeSlots();
  }, [selectedDate]);


  return (
    <>
      <PageComponents.MetaComponent title={t("user_schedule_page.title")} />
      {isSubmitting && <PreLoaderComponent2 />}
      <div className="">
        {loading && (
          <div className="flex flex-col gap-4">
            <div className="pb-4">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={45}
                animation="wave"
              />
            </div>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={45}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={45}
              animation="wave"
            />
          </div>
        )}
        {!loading && (
          <div className="flex flex-col pb-8">
            <div className="flex justify-between items-center ">
              <h1 className="p1 font-normal">
                <span
                  className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                  onClick={() => navigate("/listings")}
                >
                  {t("user_schedule_page.hbysclvfc")}
                </span>{" "}
                {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
                {listing?.model} {listing?.title ? listing?.title : ""}
              </h1>
            </div>
          </div>
        )}
        {!loading &&
          (listing?.schedule_time_slot &&
          Object.keys(listing?.schedule_time_slot).length > 0 ? (
            <div>
              <h2 className="head6 font-bold leading-7">
                {t("user_schedule_page.wfa54wvuw")}
                {listing?.review
                  ? " " + t("user_schedule_page.nwqsftfci") + " "
                  : Utils.CalculateDays(listing?.schedule_time_slot.date) < 0
                    ? " " + t("user_schedule_page.e4q55stkq") + " "
                    : " " + t("user_schedule_page.agru8rjw2") + " "}
                {t("user_schedule_page.ld0ygbgl8")}{" "}
                {moment(listing?.schedule_time_slot?.date).format(
                  "DD MMM YYYY"
                )}{" "}
                {t("user_schedule_page.ld0ygbgl8")}{" "}
                {listing?.schedule_time_slot?.start_time}
              </h2>
            </div>
          ) : (
            <form noValidate onSubmit={handleSubmit} className="">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 md:gap-6 justify-start items-center md:w-2/3">
                  <div className="InputFieldSetting">
                    <label className="p1 px-1" htmlFor="scheduleDate">
                      {t("user_schedule_page.3ldlo295g")}
                      <sup className="requireField">*</sup>
                    </label>
                    <JoyAutocomplete
                      disableClearable={true}
                      id="scheduleDate"
                      name="scheduleDate"
                      options={dates}
                      onChange={(e: any, value: any) => {
                        setSelectedDate(value);
                      }}
                      required={true}
                      placeholder={t("date_select_label")}
                      sx={themes.JoyGrayShadeInputStyle2_2}
                      disabled={listing?.schedule_time_slot}
                      startDecorator={
                        <InsertInvitation sx={{ color: "var(--main-color)" }} />
                      }
                    />
                  </div>
                  {Object.keys(selectedDate).length > 0 && (
                    <div className="InputFieldSetting">
                      <label className="p1 px-1" htmlFor="scheduleDate">
                        {t("user_schedule_page.xp0mktti2")}
                        <sup className="requireField">*</sup>
                      </label>
                      <JoyAutocomplete
                        disableClearable={true}
                        id="scheduleTime"
                        name="scheduleTime"
                        options={timeSlots}
                        onChange={(e: any, value: any) => {
                          setSelectedTimeSlot(value);
                        }}
                        required={true}
                        placeholder={t("time_slot_label")}
                        sx={themes.JoyGrayShadeInputStyle2_2}
                        startDecorator={
                          <AccessTime sx={{ color: "var(--main-color)" }} />
                        }
                      />
                    </div>
                  )}
                  <div className="flex justify-start items-center self-end md:self-start w-full md:w-fit">
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        className="hover:underline-offset-1 cursor-pointer"
                        disabled={
                          listing?.schedule_time_slot ||
                          Object.keys(selectedTimeSlot).length === 0 ||
                          loading ||
                          listing?.schedule_date_time_slot_id
                        }
                      >
                        {t("user_schedule_page.wc95ink81")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="CurrentColor"
                          viewBox="0 0 448 512"
                          className="w-4 h-4 ml-2"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </Button>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </form>
          ))}
      </div>
    </>
  );
};

export default Scheduling;

export { handleTime };
