import { useEffect, useState } from "react";
import useScreen from "./useScreen";

const useDevice = () => {
  const { width } = useScreen();

  return {
    is_xs: width < 641,
    is_sm: width < 769,
    is_md: width < 1025,
    is_lg: width < 1281,
    is_xl: width < 1537,
    "is_2xl": width > 1536,
  };
};

export default useDevice;
