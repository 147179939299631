import { useContext, useEffect, useState } from "react";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../../utils";
import AuctionQuestion from "./AuctionQuestion.component";
import QuestionAnswers from "./QuestionAnswers.component";
import AskQuestion from "./AskQuestion.component";
import Services from "../../services";
import { useLocation } from "react-router-dom";
import VerifiedBidderComponent from "./VerifiedBidder.component";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";

const SellerQA = ({ auctionEnded, data, getProfile }: any) => {
  const isUserLogedIn = isUser_loged_in();
  const location = useLocation();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [modal, setModal] = useState(false);
  const [askQuestion, setAskQuestion] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);
  const [qa, setQA] = useState([]);
  const [show, setShow] = useState<boolean>(false);

  const getQuestions = () => {
    location?.state?.uuid | data?.auction?.id &&
      Services.QA.GetQuestions(
        location?.state?.uuid | data?.auction?.id,
        offset,
        limit,
        filter
      ).then((res) => {
        if (res.status) {
          setQA(res.data);
        }
      });
  };

  useEffect(() => {
    getQuestions();
  }, []);


  return (
    <>
      <div className="">
        {/* seller qa heading */}
        <div
          className={`${
            auctionEnded && qa.length === 0
              ? "hidden"
              : "flex  gap-3 justify-between items-center "
          } `}
        >
          <div
            className={`flex gap-3 justify-between items-center w-full pt-8 pb-4 ${qa?.length > 0 ? "sm:justify-start" : ""}`}
          >
            <h3 className="font-bold head tracking-wide pb-2 ">
              {t("auction_details_page.seller_qa_section.title")}{" "}
              <span className="opacity-55 dark:opacity-80">({qa?.length})</span>
            </h3>
            {!!auctionEnded ||
              (data?.auction?.seller?.id !== data?.profile?.id && (
                <div className={`textColor my-auto p2 font-medium`}>
                  <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                    <Button
                      variant="text"
                      sx={{ textTransform: "none" }}
                      color="inherit"
                      onClick={() =>
                        isUserLogedIn
                          ? data?.profile?.is_registered_bidder
                            ? setAskQuestion(!askQuestion)
                            : setShow(true)
                          : document.getElementById("signin")?.click()
                      }
                    >
                      {t(
                        "auction_details_page.seller_qa_section.ask_question_butn_text"
                      )}
                    </Button>
                  </ThemeProvider>
                </div>
              ))}
          </div>
          <div
            className={`hidden w-full min-w-fit text-right ${qa?.length > 0 ? "sm:block" : ""}`}
          >
            <ThemeProvider theme={themes.ButtonActiveTheme_2}>
              <Button variant="text" onClick={() => setModal(true)}>
                {t(
                  "auction_details_page.seller_qa_section.view_all_questions_text"
                )}
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <div className={`py-0 sm:py-3  `}>
        {/* ask a question dialogue box */}
        {/* user can't ask question of it's own auction */}
        <div
          className={`border dark:border-[--gray] px-5 py-8 sm:py-10  md:py-14 lg:py-12 xl:py-14  rounded flex justify-center items-center ${auctionEnded || data?.auction?.seller?.id === data?.profile?.id ? "hidden" : ""}`}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              // background: theme === "dark" ? "var(--dark)" : "white",
              // color: theme === "dark" ? "var(--light)" : "inherit",
              // borderColor: theme === "dark" ? "var(--gray)" : "inherit",
            }}
            color="inherit"
            onClick={() =>
              isUserLogedIn
                ? data?.profile?.is_registered_bidder
                  ? setAskQuestion(!askQuestion)
                  : setShow(true)
                : document.getElementById("signin")?.click()
            }
          >
            {t("auction_details_page.seller_qa_section.ask_question_butn_text")}
          </Button>
        </div>
        {/* If one or more Question are ask then this swipper should be display */}
        <div className="py-0 sm:py-6 ">
          <AuctionQuestion
            data={qa}
            getQuestions={getQuestions}
            askModalOpen={askQuestion}
            setIsAskModalOpen={setAskQuestion}
          />
        </div>
      </div>
      {/* view question answers modal button */}
      <div
        className={` flex justify-end items-center py-0 my-0 ${qa?.length === 0 ? "hidden" : "sm:hidden"}`}
      >
        <ThemeProvider theme={themes.ButtonActiveTheme_2}>
          <Button onClick={() => setModal(true)} variant="text">
            {t(
              "auction_details_page.seller_qa_section.view_all_questions_text"
            )}
          </Button>
        </ThemeProvider>
      </div>

      {/* view all questions and answers modal */}
      {modal && (
        <QuestionAnswers
          modalOpen={modal}
          setIsModalOpen={setModal}
          askModalOpen={askQuestion}
          setIsAskModalOpen={setAskQuestion}
          displayAllQuestions={true}
          getQuestions={getQuestions}
          getProfile={getProfile}
          data={data}
        />
      )}

      {/* modal to display ask question form */}
      {askQuestion && (
        <AskQuestion
          modalOpen={askQuestion}
          setIsModalOpen={setAskQuestion}
          getQuestions={getQuestions}
        />
      )}

      {/* if user is not verified bidder than below modal will show before user ask a question from seller */}
      {show && (
        <VerifiedBidderComponent
          show={show}
          setShow={setShow}
          getProfile={getProfile}
        />
      )}
    </>
  );
};

export default SellerQA;
