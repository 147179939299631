import moment from "moment";

const CalculateDays = (auction_end: any) => {
  if (auction_end) {
    const auctionEndDate = new Date(moment(auction_end).local().format("LLLL"));
    const todayDate = new Date();
    let timeDifference = auctionEndDate.getTime() - todayDate.getTime();
    let dayMilliSeconds = 1000 * 60 * 60 * 24;
    return timeDifference / dayMilliSeconds;
  }
  return 0;
};

const ConvertToLocal = (dto: Date) => {
  // convert date_time_object to local object
  return new Date(moment(dto).local().format("LLLL"));
};

export { ConvertToLocal };

export default CalculateDays;
