import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  CookieBannerComponent,
  FloatingScroller,
} from "../components";

let mainFontFamily = ["Signika Negative", "Libre Franklin", "sans-serif"];

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dark:bg-[var(--dark)]">
      <div className="flex flex-col h-full w-full min-h-screen xl:container mx-auto ">
        <NavComponent />
        <div className="my-6">
          <Outlet />
        </div>
        <FooterComponent />
        <CookieBannerComponent />
        <FloatingScroller />
      </div>
    </div>
  );
}

export default Main;

export { mainFontFamily };
