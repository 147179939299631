import { useEffect, useState, useRef, useContext, Dispatch } from "react";
import { AuthPages } from "../pages";
import { Button, Popover, ThemeProvider, Badge } from "@mui/material";
import Utils from "../utils";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import Services from "../services";
import { AppImages, Images } from "../assets";
import moment from "moment";
import PreLoaderComponent from "./PreLoader.Component";
import CheckUserType from "../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";
import Context from "../context";
import { Menu, Settings } from "@mui/icons-material";
import PageComponents from "../pages/components";
import { ThemeContext } from "../context/theme.context";
import ToggleThemeComponent from "./ToggleTheme.component";

function NavComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const { lngs, lng, setLng, i18n } = useContext(Context.LanguageContext);
  const isLogedIn = CheckUserType.isUser_loged_in();
  const isAdmin = CheckUserType.isAdmin_and_loged_in();
  const notifyRef = useRef<HTMLDivElement>(null);
  const notifyMessageRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [toggleLng, setToggleLng] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(
    Services.Auth.IsUserLogedIn()
  );
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [notifications, setNotifications] = useState<any[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [toggleAuction, setToggleAuction] = useState<boolean>(false);
  const [user, setUser] = useState<any>(Services.Storage.getCurrentUser());
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorNavMenu, setAnchorNavMenu] = useState<HTMLButtonElement | null>(
    null
  );
  const [anchorLanguage, setAnchorLanguage] =
    useState<HTMLButtonElement | null>(null);
  const [anchorSetting, setAnchorSetting] = useState<HTMLButtonElement | null>(
    null
  );
  const [openVerify, setOpenVerify] = useState(false);
  const [reloadWindow, setReloadWindow] = useState(false);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      const cur = notifyRef.current;
      const node = e && e.target;
      const curMessage = notifyMessageRef.current;
      if (node && cur && cur.contains(node)) {
        setShowNotifications(!showNotifications);
      } else if (node && curMessage && curMessage.contains(node)) {
        setShowNotifications(true);
      } else {
        setShowNotifications(false);
      }
    });
  }, [showNotifications]);

  const toggleModal = (
    toggleMenuId: string,
    toggleMenuButnId: string,
    setToggle: Dispatch<boolean>
  ) => {
    toggleMenu &&
      document.addEventListener("click", (e) => {
        const toggleMenuElement = document.getElementById(toggleMenuId);
        const toggleMenuButnElement = document.getElementById(toggleMenuButnId);
        if (
          e.target !== toggleMenuElement &&
          e.target !== toggleMenuButnElement
        ) {
          setToggle(false);
        }
      });
  };

  useEffect(() => {
    toggleMenu && toggleModal("toggleMenu", "toggleMenuIcon", setToggleMenu);
  }, [toggleMenu]);

  useEffect(() => {
    toggleLng && toggleModal("lngMenus", "lngDropDown", setToggleLng);
  }, [toggleLng]);

  const showDailyEmailModal = () => {
    setIsModalOpen((open) => !open);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    return navigate(`/search/${search}`);
  };

  const handleLogout = async () => {
    await Services.Auth.Logout()
      .then(async (res) => {
        await Services.DeviceToken.DeleteDeviceToken()
          .then((res) => {
            navigate("/");
            window.location.reload();
          })
          .catch((error) => {
            navigate("/");
            window.location.reload();
          });
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  const getNotifications = () => {
    setLoadingNotifications(true);
    Services.Notification.GetNotification(offset, limit, true)
      .then((res) => {
        setLoadingNotifications(false);
        if (res.status) {
          setNotifications(res.data);
          setTotalNotifications(res.total);
        }
      })
      .catch((error) => {
        setLoadingNotifications(false);
      });
  };

  const navigateNotification = (item: any) => {
    const id: string = item?.extra_data?.listing?.id
      ? item?.extra_data?.listing?.id
      : "";
    let navigationPath: string =
      item?.notification_type === "listing_created" ||
      item?.notification_type === "application_rejected" ||
      item?.notification_type === "application_accepted"
        ? "/application/" + id
        : item?.notification_type === "meeting_scheduled_for_listing"
          ? "/scheduling/" + id
          : item?.notification_type === "listing_updated" ||
              item?.notification_type === "details_submission_rejected" ||
              item?.notification_type === "details_submission_accepted"
            ? "/specs/" + id
            : item?.notification_type === "auction_created" ||
                item?.notification_type === "auction_status_changed" ||
                item?.notification_type === "auction_won_seller" ||
                item?.notification_type === "auction_won_bidder" ||
                item?.notification_type === "auction_lost" ||
                item?.notification_type === "auction_reserve_not_met_seller" ||
                item?.notification_type === "auction_reserve_not_met_bidder"
              ? "/auction/" + id
              : "";

    let notificationTypeByPats = item?.notification_type?.split("_");

    let navigationState =
      notificationTypeByPats[0] === "listing" ||
      notificationTypeByPats[0] === "application" ||
      notificationTypeByPats[0] === "details" ||
      notificationTypeByPats[0] === "meeting" ||
      notificationTypeByPats[0] === "auction"
        ? { listing_id: id }
        : null;

    return navigate(navigationPath, {
      state: navigationState,
    });
  };

  const markNotificationAsRead = (item: any) => {
    navigateNotification(item);
    setTotalNotifications(totalNotifications - 1);
    setNotifications(
      notifications.filter((notification) => notification.id != item.id)
    );
    const data = {
      notifications: [item.id],
    };
    return Services.Notification.MarkNotificationAsRead(data);
  };

  useEffect(() => {
    isLogedIn &&
      setTimeout(() => {
        getNotifications();
      }, 5000);
  }, []);

  const closeAuctionMenu = () => setToggleAuction(false);
  const openAuctionMenu = () => setToggleAuction(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const idNotification = open ? "notification-popover" : undefined;

  useEffect(() => {
    if (location?.state) {
      setOpenVerify(location?.state?.openVerify);
      setReloadWindow(location?.state?.reloadWindow);
    }
  }, [location?.state]);

  const NavMenu = () => {
    return (
      <ul
        className="flex flex-col p-3 lg:bg-transparent bg-white xl:p-0 mt-0 font-medium rounded-lg 
      gap-2 2xl:gap-4 xl:flex-row xl:items-center justify-center menusDarkStyling xl:dark:border-none xl:dark:bg-transparent dark:rounded-none"
      >
        <li className="relative group hidden xl:block">
          <Link
            to="/"
            className={`navMenuLink ${location?.pathname === "/" || location?.pathname === "/auctions" || location?.pathname === "/past-auctions" ? "activeMenu" : ""}`}
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.1")}
          </Link>
          <ul className="bg-white menusDarkStyling dark:text-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top p-2 min-w-[240px] drop-shadow">
            <li className="p-0.5 hover:font-bold list-none">
              <Link
                to="/"
                className={
                  "cursor-pointer flex justify-between items-center gap-5 " +
                  (location?.pathname === "/" ? " activeMenu" : "")
                }
                onClick={closeAuctionMenu}
                state={{ refresh: true }}
              >
                {t("auction_menus.2")}
              </Link>
            </li>
            <li className="p-0.5 hover:font-bold list-none">
              <Link
                to="/featured-auctions"
                className={
                  "cursor-pointer flex justify-between items-center gap-5 " +
                  (location?.pathname === "/featured-auctions"
                    ? " activeMenu"
                    : "")
                }
                onClick={closeAuctionMenu}
              >
                {t("auction_menus.4")}
              </Link>
            </li>
            <li className="p-0.5 hover:font-bold list-none">
              <Link
                to="/past-auctions"
                className={
                  "cursor-pointer flex justify-between items-center gap-5 " +
                  (location?.pathname === "/past-auctions" ? " activeMenu" : "")
                }
                onClick={closeAuctionMenu}
              >
                {t("auction_menus.3")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="flex xl:hidden">
          <Link
            to="/"
            className={
              "navMenuLink" + (location?.pathname === "/" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.2")}
          </Link>
        </li>
        <li className="flex xl:hidden">
          <Link
            to="/featured-auctions"
            className={
              "navMenuLink" +
              (location?.pathname === "/featured-auctions" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.4")}
          </Link>
        </li>
        <li className="flex xl:hidden">
          <Link
            className={
              "navMenuLink" +
              (location?.pathname === "/past-auctions" ? " activeMenu" : "")
            }
            to="/past-auctions"
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
          >
            {t("auction_menus.3")}
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={
              "navMenuLink" +
              (location?.pathname === "/about" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
          >
            {t("about")}
          </Link>
        </li>
        <li>
          <Link
            to="/#News-Letter"
            className={
              "navMenuLink" +
              (location?.pathname === "/#News-Letter" ? " activeMenu" : "")
            }
            onClick={() => {
              anchorNavMenu && handleCloseNavMenu();
              showDailyEmailModal();
            }}
          >
            {t("newsletter")}
          </Link>
        </li>
        <li className="md:hidden">
          <Link
            to="/sell-car"
            className={
              "navMenuLink items-center " +
              (location?.pathname === "/sell-car" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
          >
            {t("sell")}
          </Link>
        </li>
        {/* dark mode toggler */}
        <ToggleThemeComponent />
        {!userLoggedIn && (
          <li className="flex sm:hidden">
            <button
              onClick={() => {
                setOpen(true);
                anchorNavMenu && handleCloseNavMenu();
              }}
              type="button"
              className="md:py-2.5 my-1 p-1.5 text-white md:px-4 px-2  font-semibold md:text-md text-md bg-[var(--main-color)] hover:bg-[var(--main-color)] focus:outline-none  rounded-lg text-sm text-center w-full"
              id="signin"
            >
              {t("signIn")}
            </button>
          </li>
        )}
      </ul>
    );
  };

  const handleClickNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorNavMenu(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNavMenu(null);
  };

  const openNavMenu = Boolean(anchorNavMenu);
  const idNavMenu = open ? "NavMenu-popover" : undefined;

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };

  const openLanguage = Boolean(anchorLanguage);
  const idLanguage = open ? "Language-popover" : undefined;

  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSetting(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorSetting(null);
  };

  const openSetting = Boolean(anchorSetting);
  const idSetting = open ? "Setting-popover" : undefined;

  useEffect(() => {
    document.addEventListener("focusin", function (e) {
      var element = e.target as HTMLElement;
      if (
        element?.tagName == "INPUT" ||
        element?.tagName == "TEXTAREA" ||
        element?.tagName == "SELECT"
      ) {
        var bodyStyle = document.body.style as any;
        bodyStyle && (bodyStyle.zoom = "1.0");
      }
    });

    document.addEventListener("focusout", function (e) {
      var element = e.target as HTMLElement;
      if (
        element?.tagName == "INPUT" ||
        element?.tagName == "TEXTAREA" ||
        element?.tagName == "SELECT"
      ) {
        var bodyStyle = document.body.style as any;
        bodyStyle && (bodyStyle.zoom = "1.0");
      }
    });
  }, []);

  useEffect(() => {
    if (location?.pathname?.split("/")[1] !== "search") {
      setSearch("");
    }
  }, [location]);

  return (
    <>
      <div
        className="relative h-60 xl:h-72 2xl:h-80 bg-cover bg-center bg-no-repeat"
        // header background image
        style={{
          backgroundImage: `url('${AppImages.HeaderFullImage}')`,
        }}
      >
        {/* menus */}
        <nav className="bg-transparent w-full z-20 top-0 start-0  ">
          <div
            className="max-w-screen-xxl flex sm:flex-nowrap gap-2 items-center mx-auto 
          justify-between p-1.5 md:p-6 pt-4"
          >
            {/* sit title */}
            <Link
              className="flex items-center gap-1"
              to="/"
              state={{ refresh: true }}
            >
              <img
                src={AppImages.LogoImage}
                className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                alt="AutoBids Logo"
              />
              <h1 className="self-center lg:text-[1.75rem] 2xl:text-3xl sm:text-2xl md:text-xl text-lg font-bold whitespace-nowrap text-[var(--main-color)] tracking-wider xs-text-base xs-hidden">
                {t("site_title")}
              </h1>
            </Link>
            {/* other menus */}
            <div
              className="flex md:order-2 space-x-1 md:space-x-0 md:w-auto w-screen  lg:flex-none 
            justify-end"
            >
              <div className="flex 2xl:gap-3 gap-1 sm:gap-2 flex-wrap md:flex-nowrap justify-center">
                {/* language button */}
                <button
                  className={`md:py-2.5 sm:p-1.5 text-black md:px-2.5 2xl:px-4 sm:px-2 px-1.5 font-semibold md:text-md text-md bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg text-sm text-center min-w-14 sm:min-w-20 items-center justify-between sm:flex lg:block  ${isLogedIn ? "hidden" : ""}`}
                  id="lngDropDown"
                  aria-describedby={idLanguage}
                  onClick={handleClickLanguage}
                >
                  <div className="flex justify-between gap-0.5 sm:gap-2">
                    <p className="dark:text-black">
                      {lngs &&
                        (lng && lngs[lng]
                          ? t(lngs[lng].name?.toLocaleLowerCase() || "english")
                          : t(
                              lngs["en"]?.name?.toLocaleLowerCase() || "english"
                            ))}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                      className={`w-2 ${openLanguage ? "rotate-180" : ""}`}
                    >
                      <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                    </svg>
                  </div>
                </button>
                {/* sell a car button */}
                <button
                  onClick={() => navigate("/sell-car")}
                  type="button"
                  className="md:py-2.5 p-1.5 text-black md:px-2.5 2xl:px-4 px-2 font-semibold md:text-md text-md bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg text-sm text-center hidden md:flex items-center justify-center"
                >
                  {t("sell")}
                </button>
                {/* if user not loged in then signin button will show */}
                {!userLoggedIn && (
                  <button
                    onClick={() => setOpen(true)}
                    type="button"
                    className="md:py-2.5 p-1.5 text-white md:px-2.5 2xl:px-4 px-2  font-semibold md:text-md text-md bg-[var(--main-color)] hover:bg-[var(--main-color)] focus:outline-none  rounded-lg text-sm text-center hidden sm:flex"
                    id="signin"
                  >
                    {t("signIn")}
                  </button>
                )}
                {/* if user is loged in then settings button will be show */}
                {isLogedIn && (
                  <button
                    onClick={handleClickSetting}
                    type="button"
                    id="toggleMen231u"
                    aria-describedby={idSetting}
                    className="md:p-2.5 text-black p-2 font-semibold md:text-md text-md bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg text-sm text-center sm:w-12"
                  >
                    <Settings fontSize="small" className="w-[18px] h-auto" />
                  </button>
                )}
                {/* if user is lofed in  */}
                {userLoggedIn && (
                  <button
                    type="button"
                    className="md:py-2.5 text-black p-2 font-semibold md:text-md text-md bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg text-sm text-center sm:w-12"
                    onClick={handleClick}
                  >
                    <Badge
                      className="cursor-pointer"
                      overlap="circular"
                      classes={{ badge: "notificationBadge" }}
                      badgeContent={totalNotifications}
                      max={9}
                    >
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          className="w-4 text-lg"
                          viewBox="0 0 24 24"
                        >
                          <g fill="currentColor">
                            <path
                              d="M18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 0 1-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723a4.393 4.393 0 0 0 .693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7Z"
                              opacity=".5"
                            />
                            <path d="M12.75 6a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0V6ZM7.243 18.545a5.002 5.002 0 0 0 9.513 0c-3.145.59-6.367.59-9.513 0Z" />
                          </g>
                        </svg>
                      </div>
                    </Badge>
                  </button>
                )}
                <button
                  aria-describedby={idNavMenu}
                  onClick={handleClickNavMenu}
                  type="button"
                  className="md:p-2.5 text-black p-2 sm:px-2 px-1.5 font-semibold md:text-md text-md bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg text-sm text-center xl:hidden sm:w-12 "
                >
                  <Menu fontSize="small" className="w-[18px] h-auto" />
                </button>
              </div>
            </div>
            <div
              className="items-center justify-between hidden w-full xl:flex lg:w-auto lg:order-1"
              id="navbar-sticky"
            >
              <NavMenu />
            </div>
          </div>
        </nav>
        {/* search form */}
        <div className="absolute flex justify-center w-full md:-bottom-10 -bottom-7">
          <div className="lg:w-1/2 md:w-9/12 sm:w-10/12 w-11/12 z-20 mx-auto">
            <form
              onSubmit={handleSubmit}
              className="mx-auto InputFieldSetting w-full"
            >
              <div className="searchBox focus-within:border-[--main-color] mx-auto">
                <div className="flex justify-between items-center gap-1 sm:gap-2 w-full">
                  <input
                    type="search"
                    id="default-search"
                    className="h-full w-full border-none bg-transparent"
                    placeholder="Car brand, model, and etc."
                    required
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    autoComplete="off"
                  />
                  <div className="">
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ minWidth: "36px", minHeight: "36px" }}
                      >
                        <svg
                          className="w-4 h-4 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </Button>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* notifications */}
      <Popover
        id={idNotification}
        open={openNotification}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <div className="min-w-72 menusDarkStyling">
          <div className="notify-header">
            <div className="notify-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
              <p>{t("notifications")}</p>
              <span>({totalNotifications})</span>
            </div>
            <div
              className="notify-setting "
              onClick={() => navigate("/settings")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
              </svg>
            </div>
          </div>

          <div className="notify-body">
            {loadingNotifications ? (
              <PreLoaderComponent />
            ) : (
              <div className="main-content">
                {notifications?.length > 0 &&
                  notifications?.map((item: any, index: number) => (
                    <div
                      className={`content-item cursor-pointer ${
                        item.is_unread ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        markNotificationAsRead(item);
                      }}
                    >
                      <div className="item-desc">
                        <h6 className="item-title">
                          {item.is_unread}
                          <span className="font-semibold capitalize">
                            {" "}
                            {item.title ? item.title : ""}
                            <br />
                          </span>
                        </h6>
                        <p className="text-sm">{item.body}</p>
                        <p className="item-ago">
                          {moment(item.created_at).fromNow()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Popover>
      {/* nav menu for mobile */}
      <Popover
        id={idNavMenu}
        open={openNavMenu}
        anchorEl={anchorNavMenu}
        onClose={handleCloseNavMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <NavMenu />
      </Popover>
      {/* language menu */}
      <Popover
        id={idLanguage}
        open={openLanguage}
        anchorEl={anchorLanguage}
        onClose={handleCloseLanguage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <nav className="min-w-36 p-4 menusDarkStyling">
          <ul className="list-none">
            <ThemeProvider theme={themes.ButtonActiveTheme_2}>
              {lngs &&
                Object.keys(lngs)?.length > 0 &&
                Object.keys(lngs)?.map((lng2CodeKey: string, index) => (
                  <li className="p-0.5" key={index}>
                    <Button
                      className="text-lg"
                      onClick={(e) => {
                        setToggleLng(false);
                        setLng && setLng(lng2CodeKey);
                        i18n?.changeLanguage(lng2CodeKey);
                        handleCloseLanguage();
                      }}
                      variant={lng2CodeKey === lng ? "contained" : "text"}
                      sx={{
                        padding: 0,
                        margin: 0,
                        textAlign: "left",
                      }}
                      fullWidth
                    >
                      {t(
                        lngs[lng2CodeKey]?.name?.toLocaleLowerCase() ||
                          "english"
                      )}
                    </Button>
                  </li>
                ))}
            </ThemeProvider>
          </ul>
        </nav>
      </Popover>
      {/* settings menu */}
      <Popover
        id={idSetting}
        open={openSetting}
        anchorEl={anchorSetting}
        onClose={handleCloseSetting}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <nav className="min-w-48 p-4 menusDarkStyling">
          <ul className="font-medium">
            {userLoggedIn && (
              <li className="p-0.5 hover:font-bold list-none">
                <NavLink
                  to={isAdmin ? "/dashboard" : "/profile"}
                  className={({ isActive, isPending }) =>
                    `navSettingLink flex flex-row justify-between items-center gap-5  ${
                      isActive ? "font-bold" : ""
                    } ${location?.pathname === "/profile" ? " activeMenu" : ""}`
                  }
                >
                  <p>
                    {t(
                      isAdmin ? "dashboard_sidebar.u6a5hj5pr" : "account_label"
                    )}
                  </p>
                  <img
                    src={
                      user?.avatar
                        ? process.env.REACT_APP_HOST_API_KEY +
                          "/" +
                          user?.avatar
                        : Images.ProfileIcon2
                    }
                    loading="lazy"
                    alt="profile picture"
                    className="w-6 h-6 border dark:border-[--dark] shadow-sm drop-shadow-sm rounded-full	"
                  />
                </NavLink>
              </li>
            )}
            {userLoggedIn && (
              <>
                <li className="p-0.5 hover:font-bold list-none">
                  <NavLink
                    to="/listings"
                    className={({ isActive, isPending }) =>
                      `navSettingLink flex justify-between items-center gap-5    ${
                        isActive ? "font-bold" : ""
                      } ${location?.pathname === "/listings" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_listings")}
                  </NavLink>
                </li>
                <li className="p-0.5 hover:font-bold list-none">
                  <NavLink
                    to="/watch-list"
                    className={({ isActive, isPending }) =>
                      `navSettingLink flex justify-between items-center gap-5  ${
                        isActive ? "font-bold" : ""
                      } ${location?.pathname === "/watch-list" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_watch_lists")}
                  </NavLink>
                </li>
                <li className="p-0.5 hover:font-bold list-none">
                  <NavLink
                    to="/settings"
                    className={({ isActive, isPending }) =>
                      `navSettingLink flex justify-between items-center gap-5  ${
                        isActive ? "font-bold" : ""
                      } ${location?.pathname === "/settings" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_settings")}
                  </NavLink>
                </li>
                <li className="p-0.5 hover:font-bold list-none">
                  <p
                    className="navSettingLink  "
                    onClick={() => handleLogout()}
                  >
                    {t("sign_out")}
                  </p>
                </li>
              </>
            )}
            <button
              className=" w-full md:py-3 text-black md:px-4 px-2 text-lg bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-sm min-w-20 flex items-center justify-between sm:hidden gap-2 font-semibold"
              id="lngDropDown"
              aria-describedby={idLanguage}
              onClick={handleClickLanguage}
            >
              <p>
                {lngs &&
                  (lng && lngs[lng]
                    ? t(lngs[lng].name?.toLocaleLowerCase() || "English")
                    : t(lngs["en"]?.name?.toLocaleLowerCase() || "English"))}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                className={`w-2 ${openLanguage ? "rotate-180" : ""}`}
              >
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
              </svg>
            </button>
          </ul>
        </nav>
      </Popover>
      {open && <AuthPages.Login openLogin={open} setOpenLogin={setOpen} />}
      {isModalOpen && <AuthPages.DailyEmail setIsModalOpen={setIsModalOpen} />}
      {openVerify && (
        <PageComponents.VerificationBrowse
          setOpenVerify={setOpenVerify}
          openVerify={openVerify}
          reloadWindow={reloadWindow}
          setReloadWindow={setReloadWindow}
        />
      )}
    </>
  );
}

export default NavComponent;
