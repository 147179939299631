import { useContext, useEffect, useState } from "react";
import { Input, Autocomplete, Button as JoyButton } from "@mui/joy";
import CategoryIcon from "@mui/icons-material/Category";
import SvgIcon from "@mui/joy/SvgIcon";
import { styled } from "@mui/joy";
import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  ThemeProvider,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useScreen } from "../../hooks";
import Utils from "../../utils";
import _ from "lodash";
import Services from "../../services";
import Swal from "sweetalert2";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ThemeContext } from "../../context/theme.context";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

type previewType = {
  index: number;
  id?: number;
  name: string;
  b64: any;
  preview: any | string;
  error: string;
  selectedMedia: string;
  isUploaded: boolean;
};

type mediaType = {
  photos?: any;
  videos?: string[];
};

const Media = () => {
  const { t } = useTranslation();
  const { width } = useScreen();
  const params = useParams();
  const themes = Utils.Themes();
  const navigate = useNavigate();
  const { isDark } = useContext(ThemeContext);
  const [mediaTypes, setMediaTypes] = useState<string[]>([
    "exterior",
    "interior",
    "mechanical",
    "docs",
    "other",
  ]);
  const [selectedMedia, setSelectedMedia] = useState<string>("");
  const [photos, setPhotos] = useState<any>({
    exterior: [],
    interior: [],
    mechanical: [],
    docs: [],
    other: [],
  });
  const [photoPreviews, setPhotoPreviews] = useState<any[]>([]);
  const [photoId, setPhotoId] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [videoUrls, setVideoUrls] = useState<any[]>([]);
  const [noOfVideos, setNoOfvideos] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailApproved, setDetailsApproved] = useState<boolean>(false);
  const [listId, setListId] = useState<number>(0);
  const [savePhoto, setSavePhoto] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [listing, setListing] = useState<any>({});
  const [value, setValue] = useState<number>(0);
  const [countedPhotos, setCountedPhotos] = useState<number>(0);
  const [focus, setFocus] = useState<boolean>(false);
  const [enableNextStep, setEnableNextStep] = useState<boolean>(false);
  const [uploadedPhotos, setUploadedPhotos] = useState<any>({
    exterior: 0,
    interior: 0,
    mechanical: 0,
    docs: 0,
    other: 0,
  });

  const resetPhotoMedia = () => {
    setPhotos({
      exterior: [],
      interior: [],
      mechanical: [],
      docs: [],
      other: [],
    });
    setSavePhoto(false);
  };

  const resetVideoMedia = () => {
    setVideoUrls([]);
    setNoOfvideos(1);
  };

  const handleSubmit = (e?: any, photos?: any) => {
    // upload videos / images to the server using
    e?.preventDefault();

    const data: mediaType = {};
    let videos: string[] = [];
    videoUrls?.length > 0 &&
      videoUrls?.map((item) => {
        !item.error && videos.push(item.url);
      });
    photos ? (data.photos = photos) : (data.videos = videos);
    setLoading(true);
    Services.Listing.UpdateLisitng(listId, data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          photos ? resetPhotoMedia() : resetVideoMedia();
          if (photos) {
            setCountedPhotos(
              countedPhotos + data.photos[selectedMedia]?.length
            );
            setUploadedPhotos((old: any) => {
              return {
                ...old,
                [selectedMedia]:
                  data.photos[selectedMedia]?.length +
                  uploadedPhotos[selectedMedia],
              };
            });
          } else {
            getListingInfo(true, true);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  useEffect(() => {
    let countPhotos = 0;
    if (listing?.photos && Object.keys(listing?.photos)?.length > 0) {
      Object.keys(listing?.photos)?.map((key) => {
        countPhotos += listing.photos[key].length;
      });
    }
    setCountedPhotos(countPhotos);
  }, [listing?.photos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reOrderPhotosByCategory = () => {
    let photoPreviewsOld = [...photoPreviews];
    let photoPreviewsNew: any = {
      exterior: [],
      interior: [],
      mechanical: [],
      docs: [],
      other: [],
    };
    photoPreviewsOld?.length > 0 &&
      photoPreviewsOld?.map((item, index) => {
        const object_info = photoPreviewsNew[item.selectedMedia];
        if (object_info) {
          photoPreviewsNew[item.selectedMedia] = [
            ...photoPreviewsNew[item.selectedMedia],
            item,
          ];
        }
      });
    setPhotoPreviews([
      ...photoPreviewsNew.exterior,
      ...photoPreviewsNew.interior,
      ...photoPreviewsNew.mechanical,
      ...photoPreviewsNew.docs,
      ...photoPreviewsNew.other,
    ]);
  };

  useEffect(() => {
    reOrderPhotosByCategory();
  }, [photos, listId, listing]);

  const handleNext = () => {
    if (
      uploadedPhotos &&
      uploadedPhotos["exterior"] > 3 &&
      uploadedPhotos["interior"] > 3 &&
      uploadedPhotos["mechanical"] > 1
      // &&
      // uploadedPhotos["docs"] > 1
    ) {
      return setEnableNextStep(true);
    }
    return setEnableNextStep(false);
  };

  useEffect(() => {
    handleNext();
  }, [uploadedPhotos]);

  const getListingInfo = (
    load_new_data?: boolean,
    navigateToSchedule?: boolean,
    photoField?: string
  ) => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status && res.data) {
          setListing(res.data);
          const data = res.data;
          // if (
          //   data.photos?.exterior?.length > 0 &&
          //   data.photos?.interior?.length > 0 &&
          //   data.photos?.mechanical?.length > 0 &&
          //   data.photos?.docs?.length > 0 &&
          //   ((listing?.schedule_date_time_slot !== undefined &&
          //     !listing?.schedule_date_time_slot) ||
          //     (listing?.schedule_time_slot !== undefined &&
          //       !listing?.schedule_time_slot))
          // ) {
          //   if (navigateToSchedule) {
          //     return navigate(listing?.owner_is_dealer ? "/listing/" : "/scheduling/" + params.list_id);
          //   } else if (!location.state?.returnOneTime) {
          //     return navigate("/media/" + params.list_id, {
          //       state: { refreshAuction: true, returnOneTime: true },
          //     });
          //   }
          // }
          // if (
          //   data.application_status !== "approved" ||
          //   !data.title ||
          //   !data.description ||
          //   !data.engine ||
          //   !data.drive_train ||
          //   !data.body_style ||
          //   !data.exterior_color ||
          //   !data.interior_color ||
          //   !data.service_history
          // ) {
          //   return navigate(`/application/${params?.list_id}`);
          // }
          // if (data.application_status !== "approved") {
          //   return navigate(`/application/${params?.list_id}`);
          // } else if (data.details_submission_status === "approved") {
          //   if (data?.auction) {
          //     return navigate(`/auction/${params?.list_id}`);
          //   } else if (!data?.owner_is_dealer && !data?.review) {
          //     return navigate(`/scheduling/${params?.list_id}`);
          //   } else if (data?.review) {
          //     return navigate(`/review/${params?.list_id}`);
          //   }
          // }
          if (data.application_status !== "approved") {
            return navigate(`/application/${params?.list_id}`);
          } else if (data.details_submission_status === "approved") {
            if (data?.auction) {
              return navigate(`/auction/${params?.list_id}`);
            }
          }
          setListId(data.id);
          let previewArray: any[] = [];
          let id = photoId;
          // set counted photos of every category
          setUploadedPhotos({
            exterior: data?.photos?.exterior?.length || 0,
            interior: data?.photos?.interior?.length || 0,
            mechanical: data?.photos?.mechanical?.length || 0,
            docs: data?.photos?.docs?.length || 0,
            other: data?.photos?.other?.length || 0,
          });
          // set video urls
          data?.videos?.length > 0 &&
            setVideoUrls(
              data?.videos?.map((url: string, index: number) => {
                return { index, error: "", url };
              })
            );
          setNoOfvideos(data?.videos?.length > 0 ? data?.videos?.length : 1);
          // fetch data to specific data
          if (!photoField) {
            Object.keys(data?.photos)?.length > 0 &&
              Object.keys(data?.photos)?.map(
                (photoKey: string, index: number) => {
                  data?.photos[photoKey]?.length > 0 &&
                    data?.photos[photoKey]?.map(
                      (photoPath: string, index: number) => {
                        let objectResponse: any = handleImagePreview(
                          photoPath,
                          id,
                          true,
                          photoKey
                        );
                        previewArray.push(objectResponse);
                        ++id;
                      }
                    );
                }
              );
          } else {
            data?.photos[photoField]?.length > 0 &&
              data?.photos[photoField]?.map(
                (photoPath: string, index: number) => {
                  let objectResponse: any = handleImagePreview(
                    photoPath,
                    id,
                    true,
                    photoField
                  );

                  previewArray.push(objectResponse);
                  ++id;
                }
              );
          }
          setPhotoId(id);
          setPhotoPreviews(previewArray);
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params]);

  function getImageSize(url: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };

      img.onerror = () => {
        reject(
          new Error(t("submit_page.validation.carPhotos.load_image_error"))
        );
      };
    });
  }

  const handleImagePreview = (
    file: any,
    index: number,
    isUploaded: boolean,
    mediaType?: string
  ) => {
    let fileObj: previewType = {
      index: index,
      name: "",
      b64: null,
      error: "",
      preview: "",
      selectedMedia: mediaType ? mediaType : selectedMedia,
      isUploaded: isUploaded,
    };
    if (isUploaded) {
      fileObj.preview = file;
    }
    return fileObj;
  };

  const resizeFile = (file: any, maxWidth?: number, maxHeight?: number) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth ? maxWidth : 500,
        maxHeight ? maxHeight : 332,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        // "base64"
        "file"
      );
    });

  const handleFilePreview = async (
    image: any,
    index: number,
    isUploaded: boolean,
    mediaType?: string
  ) => {
    let imgObj: any = {
      index: index,
      name: "",
      b64: null,
      error: "",
      preview: "",
      selectedMedia: mediaType ? mediaType : selectedMedia,
      isUploaded: isUploaded,
    };
    if (isUploaded) {
      imgObj.preview = image;
      return imgObj;
    } else {
      if (
        image.type === "image/jpg" ||
        image.type === "image/png" ||
        image.type === "image/jpg" ||
        image.type === "image/jpeg"
      ) {
        const imgSize = image.size / (1024 * 1024);
        if (imgSize <= 2) {
          let reader = new FileReader();
          reader.readAsDataURL(image);
          imgObj.b64 = URL.createObjectURL(image);
          return getImageSize(imgObj.b64)
            .then(({ width, height }: any) => {
              // return imgObj;
              if (width < 1024 || height < 768) {
                imgObj.error = t(
                  "submit_page.validation.carPhotos.min_image_size"
                );
              } else if (width > 3072 || height > 2304) {
                imgObj.error = t(
                  "submit_page.validation.carPhotos.max_image_size2"
                );
              } else if (width < height) {
                imgObj.error = t(
                  "submit_page.validation.carPhotos.img_landscape_message"
                );
              } else if (width / height < 1.25 || width / height > 1.75) {
                imgObj.error = t(
                  "submit_page.validation.carPhotos.image_ratio_message"
                );
              } else {
                imgObj.error = "";
              }
              return imgObj;
            })
            .catch((error) => {
              imgObj.error = t("unkown_error");
              return imgObj;
            });
        } else {
          imgObj.error = t("submit_page.validation.carPhotos.max_image_size");
          return imgObj;
        }
      } else {
        imgObj.error = t(
          "submit_page.validation.carPhotos.unsupported_file_format"
        );
        return imgObj;
      }
    }
  };

  // handle photos to upload images
  const handlePhotos = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = [...e.target.files];
      let id = photoId;
      let photoLength = photos[selectedMedia].length;
      let selectedArray: any[] = photos[selectedMedia];
      let previewArray: any[] = [...photoPreviews];
      let resizedPhotos: any[] = [];

      // Use Promise.all to await all file processing asynchronously
      await Promise.all(
        files.map(async (file: any) => {
          file.id = id;
          selectedArray.push(file);

          try {
            // Handle file preview asynchronously
            let objectResponse: any = await handleFilePreview(file, id, false);

            // Process the response from handleFilePreview
            if (objectResponse && !objectResponse.error) {
              // Resize the file asynchronously
              let resizedImage: any = await resizeFile(file, 768, 512);
              if (resizedImage) {
                resizedPhotos.push(resizedImage);
              }
            }

            // Push the object response to the preview array
            previewArray.push(objectResponse);
          } catch (error) {
            console.error("Error processing file:", error);
            // Handle any errors during file processing if needed
          }

          id++; // Increment ID for the next file
        })
      );

      // Update state with the modified arrays
      setPhotos((old: any) => ({ ...old, [selectedMedia]: resizedPhotos }));
      setPhotoId(id);
      setPhotoPreviews(previewArray);
      handleSubmit(null, { [selectedMedia]: resizedPhotos });
    }
    return (e.target.value = "");
  };

  const handleDeleteImage = (index: number, item: any) => {
    setIsDeleting(true);
    if (item.preview) {
      const data = {
        photos: item.preview,
      };
      Services.Listing.DeleteListingPhoto(listId, data)
        .then((res) => {
          setIsDeleting(false);
          if (res.status) {
            getListingInfo(
              false,
              false,
              item?.selectedMedia === selectedMedia ? item?.selectedMedia : ""
            );
          }
        })
        .catch((error) => {
          setIsDeleting(false);
        });
    } else {
      setIsDeleting(false);
      setPhotoPreviews((old: any) =>
        old.filter((preview: any, key: number) => key != index)
      );
    }
  };

  function getIDfromURL(url: string) {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url?.match(regExp);
    return match && match[2].length === 11 ? match[2] : "";
  }

  const handleChangeValue = (e: any, id: string, index: number) => {
    let changeInExistingUrl = false;
    const urlObj = { index: index, url: e.target.value?.trim(), error: "" };
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const isYoutubeUrl = regExp.test(e.target.value);
    if (isYoutubeUrl) {
      const youtubeId = getIDfromURL(urlObj.url);
      urlObj.url = "https://www.youtube.com/embed/" + youtubeId;
      const iFrame = document.createElement("iframe");
      iFrame.src = urlObj.url;
    } else {
      urlObj.url && (urlObj.error = t("media_page.rmty4maxa"));
    }
    if (videoUrls.length > 0) {
      let urls: any[] = videoUrls?.map((item: any, i: number) => {
        if (!urlObj.error && i != index) {
          item?.url == urlObj?.url &&
            (urlObj.error = t("media_page.2fxgyrynk"));
        }
        if (i == index) {
          item.url = urlObj.url;
          changeInExistingUrl = true;
          item.error = urlObj.error;
        }
        return item;
      });
      setVideoUrls(urls);
    }
    if (!changeInExistingUrl) {
      setVideoUrls((old: any) => {
        return [...old, urlObj];
      });
    }
  };

  useEffect(() => {
    step === 1 && document.getElementById("video-url-0")?.focus();
  }, [step]);

  const AddMoreExisitng = () => {
    if (noOfVideos !== 0) {
      const element = document.getElementById(
        `video-url-${noOfVideos - 1}`
      ) as HTMLInputElement;
      if (element.value) {
        setNoOfvideos(noOfVideos + 1);
      } else {
        element?.focus();
      }
    } else {
      setNoOfvideos(noOfVideos + 1);
    }
    setFocus(true);
  };

  useEffect(() => {
    if (focus) {
      const element = document.getElementById(
        `video-url-${noOfVideos - 1}`
      ) as HTMLInputElement;
      element?.focus();
    }
  }, [focus, noOfVideos]);

  const handleDeleteVideo = (index: number, item: string) => {
    setVideoUrls(videoUrls.filter((data: string, i: number) => i !== index));
    setNoOfvideos(noOfVideos > 1 ? noOfVideos - 1 : 1);
  };

  const handleKey = (e: any, index: number) => {
    if (e.key === "Backspace") {
      if (noOfVideos > 1) {
        !videoUrls[index]?.url && handleDeleteVideo(index, videoUrls[index]);
      }
    }
  };

  const groupButnStyle = {
    textWrap: "nowrap",
    paddingX: "12px",
    minWidth: "max-content !important",
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("media_page.title")} />
      {loading && <PreLoaderComponent2 />}
      <div className="container mx-auto">
        <div className="flex flex-col px-1 lg:px-3">
          <div className="flex flex-col lg:flex-row justify-start lg:justify-between gap-2 items-end">
            <h1 className="p1 font-normal">
              <span
                className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                onClick={() => navigate("/listings")}
              >
                {t("media_page.39dobvfta")}
              </span>{" "}
              {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
              {listing?.model} {listing?.title ? listing?.title : ""}
            </h1>
            <ThemeProvider theme={themes.ButtonTheme_2}>
              <Tooltip
                title={enableNextStep ? t("next") : t("sopqho")}
                placement="left"
              >
                <Button
                  variant="contained"
                  className={`hover:underline-offset-1 cursor-pointer ${!enableNextStep ? "opacity-30" : ""}`}
                  onClick={() => enableNextStep && navigate("/specs/" + listId)}
                >
                  {t("next")}
                  <ArrowRightAltIcon className="ms-2" />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
          {/* tabs */}
          <div className=" pt-8">
            <ThemeProvider theme={themes.ButtonTheme_2}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                fullWidth
                className="overflow-x-auto"
              >
                <Button
                  variant={value === 0 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(0);
                    setSelectedMedia("");
                    getListingInfo();
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.grnzk29ac")} ({countedPhotos})
                </Button>
                <Button
                  variant={value === 1 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(1);
                    setSelectedMedia("exterior");
                    getListingInfo(false, false, "exterior");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.xe5ko5095")}* ({uploadedPhotos?.exterior})
                </Button>
                <Button
                  variant={value === 2 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(2);
                    setSelectedMedia("interior");
                    getListingInfo(false, false, "interior");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.5tedmhy45")}* ({uploadedPhotos?.interior})
                </Button>
                <Button
                  variant={value === 3 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(3);
                    setSelectedMedia("mechanical");
                    getListingInfo(false, false, "mechanical");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.2kcwkj15u")}* ({uploadedPhotos?.mechanical})
                </Button>
                <Button
                  variant={value === 4 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(4);
                    setSelectedMedia("docs");
                    getListingInfo(false, false, "docs");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.463ctq9zw")} ({uploadedPhotos?.docs})
                </Button>
                <Button
                  variant={value === 5 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(5);
                    setSelectedMedia("other");
                    getListingInfo(false, false, "other");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.tyatcqgrr")} ({uploadedPhotos?.other})
                </Button>
                <Button
                  variant={value === 6 ? "contained" : "outlined"}
                  onClick={() => {
                    setValue(6);
                    setSelectedMedia("");
                  }}
                  sx={groupButnStyle}
                >
                  {t("media_page.gisgkhqyk")} (
                  {listing?.videos?.length ? listing?.videos?.length : 0})
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          </div>
          {/* upload image message for users to tell user how many minimum image is require for every category */}
          <div className="pt-4">
            {value !== 6 &&
              value !== 5 &&
              value !== 4 &&
              (value === 0
                ? countedPhotos < 10
                : photos[selectedMedia]?.length <
                  (value === 5 ? 2 : value === 3 ? 6 : 4)) && (
                <label
                  className="text-[var(--main-color)] font-bold p1 pt-8"
                  htmlFor="upload_photos"
                >
                  {t("media_page.r427t9l90")}{" "}
                  {value === 0 ? 10 : value === 3 ? 2 : 4} images{" "}
                  {value !== 0 ? t("media_page.20mbp48s6") + " " : ""}
                  <span className="capitalize">
                    {value !== 0 ? selectedMedia : ""}
                  </span>
                </label>
              )}
          </div>
          {/* selector to upload images */}
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-col gap-8 pt-4">
              {value < 6 ? (
                <>
                  <div>
                    <div
                      className="flex flex-col sm:flex-row gap-2 md:gap-4 justify-end items-end"
                      key={value}
                    >
                      {value === 0 && (
                        <div className="InputFieldSetting sm:w-1/2">
                          <Autocomplete
                            id="photo_type"
                            name="photo_type"
                            options={mediaTypes}
                            value={selectedMedia}
                            onChange={(e: any, value: any) =>
                              setSelectedMedia(value)
                            }
                            disableClearable={true}
                            placeholder={t("media_page.pkaeibj5y")}
                            startDecorator={
                              <CategoryIcon
                                sx={{ color: "var(--main-color)" }}
                              />
                            }
                            sx={themes.JoyGrayShadeInputStyle2_2}
                          />
                        </div>
                      )}
                      <div
                        className={`InputFieldSetting  ${
                          value === 0 ? "sm:w-1/2" : "w-full"
                        }`}
                      >
                        <JoyButton
                          id="upload_button"
                          fullWidth
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="outlined"
                          color="neutral"
                          disabled={
                            listing?.details_submission_status !== "pending"
                          }
                          sx={
                            (themes.JoyGrayShadeInputStyle2_2,
                            (theme) => ({
                              "&:focus": {
                                borderColor: isDark
                                  ? "var(--gray)"
                                  : "var(--main-color)",
                                borderWidth: "2px",
                                background: "var(--input-bg-faded)",
                                color: "black",
                              },
                              "&:hover": {
                                borderColor: isDark
                                  ? "var(--gray)"
                                  : "var(--main-color)",
                                borderWidth: "2px",
                                background: "var(--input-bg-faded)",
                                color: "black",
                              },
                              color: isDark ? "var(--light)" : "black",
                              borderColor: "var(--gray)",
                            }))
                          }
                          startDecorator={
                            <SvgIcon sx={{ color: "var(--main-color)" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                            </SvgIcon>
                          }
                        >
                          {t("media_page.9rcp6j831")}{" "}
                          {selectedMedia
                            ? selectedMedia[0].toUpperCase() +
                              selectedMedia.substring(1)
                            : ""}{" "}
                          {t("media_page.iu186yil4")}
                          <VisuallyHiddenInput
                            type="file"
                            multiple
                            id="fileInput"
                            name="upload_photos"
                            disabled={
                              !selectedMedia ||
                              listing?.details_submission_status !== "pending"
                            }
                            onChange={(e: any) => {
                              handlePhotos(e);
                            }}
                            accept="image/*"
                          />
                        </JoyButton>
                      </div>
                    </div>
                  </div>
                  <div className="InputFieldSetting">
                    <ImageList
                      sx={{
                        maxHeight: "100vh",
                        boxShadow:
                          "inset 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1), 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
                        borderRadius: "1rem",
                        ".MuiImageListItem-root .MuiImageListItem-img": {
                          height: "300px",
                        },
                      }}
                      cols={
                        photoPreviews.length > 0
                          ? width <= 768
                            ? 1
                            : width <= 1024
                              ? 2
                              : 3
                          : 1
                      }
                    >
                      {photoPreviews?.length > 0 ? (
                        photoPreviews?.map((item, index) => (
                          <ImageListItem
                            key={index}
                            sx={{
                              maxHeight: "100vh",
                              ".MuiImageListItem-root .MuiImageListItem-img": {
                                height: "300px",
                              },
                            }}
                          >
                            <img
                              src={
                                item.isUploaded
                                  ? `${process.env.REACT_APP_HOST_API_KEY}/${item.preview}`
                                  : item.b64
                              }
                              srcSet={
                                item.isUploaded
                                  ? `${process.env.REACT_APP_HOST_API_KEY}/${item.preview}`
                                  : item.b64
                              }
                              alt={item.name}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.selectedMedia}
                              sx={{
                                boxShadow:
                                  "inset 0px 10px 30px -1px rgba(0,0,0,0.1), 5px 10px 30px -1px rgba(0,0,0,0.1)",
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                  "rgba(0,0,0,0.5) 50%" +
                                  "rgba(0,0,0,0.3) 80%, rgba(0,0,0,0) 100%)",
                                textTransform: "capitalize",
                                ".MuiImageListItemBar-subtitle": {
                                  color: item.error
                                    ? "var(--danger-color)"
                                    : "var(--light-color)",
                                  fontWeight: "bold",
                                  letterSpacing: "1px",
                                  fontSize: "1rem",
                                },
                              }}
                              subtitle={
                                item.error
                                  ? item.error
                                  : item.isUploaded
                                    ? t("media_page.2lb85t42p")
                                    : t("media_page.akoo8beoy")
                              }
                              position="top"
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.selectedMedia}`}
                                  onClick={(e: any) =>
                                    // listing?.details_submission_status !==
                                    //   "pending" &&
                                    handleDeleteImage(index, item)
                                  }
                                  disabled={
                                    isDeleting ||
                                    listing?.details_submission_status !==
                                      "pending"
                                  }
                                >
                                  <CancelIcon />
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        ))
                      ) : (
                        <p className="flex justify-center items-center">
                          {t("media_page.mim5ozzre")}
                        </p>
                      )}
                    </ImageList>
                  </div>
                </>
              ) : (
                <>
                  {/* images list */}
                  <div className="flex flex-col sm:flex-row gap-2 md:gap-4">
                    <div className="py-3 w-full">
                      <div className="flex flex-col sm:flex-row flex-center sm:justify-between items-center pb-2">
                        <label className="p1" htmlFor="existing_listing">
                          {t("media_page.ga35tjlcl")}
                        </label>
                      </div>
                      {_.times(noOfVideos, (index) => {
                        return (
                          <div key={index} className="py-1">
                            <Input
                              name="video-urls"
                              fullWidth
                              value={
                                videoUrls[index]?.url
                                  ? videoUrls[index]?.url
                                  : ""
                              }
                              id={`video-url-${index}`}
                              onChange={(e) =>
                                handleChangeValue(
                                  e,
                                  `video-url-${index}`,
                                  index
                                )
                              }
                              sx={
                                videoUrls[index]?.error
                                  ? themes.JoyInputError_2
                                  : themes.JoyGrayShadeInputStyle2_2
                              }
                              onKeyUp={(e) => handleKey(e, index)}
                            />
                            <WarningAlert message={videoUrls[index]?.error} />
                          </div>
                        );
                      })}
                      <div className="flex flex-col sm:flex-row flex-center sm:justify-between items-center pt-4">
                        <ThemeProvider theme={themes.ButtonTheme_2}>
                          <Button
                            variant="text"
                            className="hover:underline-offset-1 cursor-pointer"
                            onClick={() => AddMoreExisitng()}
                            disabled={
                              noOfVideos >= 10 ||
                              listing?.details_submission_status !== "pending"
                            }
                          >
                            + {t("media_page.dv913vi2i")}
                          </Button>
                        </ThemeProvider>
                        <ThemeProvider theme={themes.ButtonTheme_2}>
                          <Button
                            type="submit"
                            variant="contained"
                            className="hover:underline-offset-1 cursor-pointer"
                            disabled={
                              listing?.details_submission_status !== "pending"
                            }
                          >
                            {t("media_page.8lu20mhyf")}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="CurrentColor"
                              viewBox="0 0 448 512"
                              className="w-4 h-4 ml-2"
                            >
                              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                          </Button>
                        </ThemeProvider>
                      </div>
                    </div>
                  </div>
                  <div className="InputFieldSetting">
                    {/* videos list */}
                    <ImageList
                      sx={{
                        height: 500,
                        boxShadow:
                          "inset 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1), 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
                        borderRadius: "1rem",
                        gap: "1rem",
                      }}
                      cols={width <= 768 ? 1 : width <= 1024 ? 2 : 3}
                    >
                      {videoUrls?.length > 0 &&
                        videoUrls?.map((item: any, index: number) => (
                          <div>
                            <ImageListItem
                              key={index}
                              sx={{
                                maxHeight: "100vh",
                                boxShadow:
                                  "inset 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1), 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1)",
                                ".MuiImageListItem-root .MuiImageListItem-img":
                                  {
                                    height: "300px",
                                  },
                              }}
                              cols={width <= 768 ? 1 : width <= 1024 ? 2 : 3}
                            >
                              <iframe
                                src={item.url}
                                id={`iframContent-${index}`}
                                loading="lazy"
                                height={"300px"}
                              ></iframe>
                              <ImageListItemBar
                                title={item.error}
                                sx={{
                                  background: item.error
                                    ? "#000000"
                                    : "transparent",
                                  ".MuiImageListItemBar-title": {
                                    color: item.error
                                      ? "var(--danger-color)"
                                      : "var(--main-color)",
                                  },
                                }}
                                actionIcon={
                                  <CancelIcon
                                    onClick={(e: any) =>
                                      // listing?.details_submission_status !==
                                      //   "pending" &&
                                      handleDeleteVideo(index, item)
                                    }
                                    className="bg-[var(--light-color)] text-red-900 rounded-full cursor-pointer mb-1 mr-1"
                                  />
                                }
                              />
                            </ImageListItem>
                          </div>
                        ))}
                    </ImageList>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Media;
