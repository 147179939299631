import React, { useEffect, memo } from "react";
import { Helmet } from "react-helmet";
import { Images } from "../../assets";

interface MetaType {
  title?: string;
  canonicalUrl?: string;
  description?: string;
  keywords?: string[];
  sitename?: string;
  //i.e business, product, profile, website
  ogType?: string;
  ogImage?: string | null;
  ogVideo?: string;
}

const MetaComponent = memo(function MetaComponent({
  title,
  canonicalUrl,
  description,
  keywords,
  sitename,
  ogType,
  ogImage,
  ogVideo,
}: MetaType) {
  const default_title =
    "Auto Bids | Auto Bidding Platform Let's do a bid and win a car";

  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? title : default_title}</title>
        <link
          rel="canonical"
          href={canonicalUrl ? canonicalUrl : window.location.href}
        />
        <meta name="site_name" content={sitename ? sitename : "Auto Bids"} />
        <meta
          name="description"
          content={description ? description : default_title}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <base target="_blank" href={window.location.href} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="keywords"
          content={
            keywords && keywords?.length > 0
              ? keywords?.join(", ")
              : "Auto Bids"
          }
        />
        <meta name="googlebot" content="all" />
        <meta name="robots" content="all" />
        <meta itemProp="image" content={ogImage ? ogImage : Images.Logo} />

        {/* open graph information manager */}
        <meta property="og:title" content={title ? title : "Auto Bids"} />
        <meta
          property="og:site_name"
          content={sitename ? sitename : "Auto Bids"}
        />
        <meta
          property="og:url"
          content={canonicalUrl ? canonicalUrl : window.location.href}
        />
        <meta
          property="og:description"
          content={description ? description : default_title}
        />
        <meta property="og:type" content={ogType ? ogType : "website"} />
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogVideo && <meta property="og:image" content={ogVideo} />}

        {/*  Optional: For Twitter Cards  */}
        {ogImage && <meta name="twitter:card" content={ogImage} />}
        <meta name="twitter:title" content={title ? title : "Auto Bids"} />
        <meta
          name="twitter:description"
          content={description ? description : default_title}
        />
        {ogImage && <meta name="twitter:image" content={ogImage} />}
      </Helmet>
    </div>
  );
});

export default MetaComponent;
