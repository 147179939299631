import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Tune,
} from "@mui/icons-material";
import { Box, Button, Drawer, ThemeProvider } from "@mui/material";
import SliderWrapper from "../../components/SliderWrapper";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import Services from "../../services";
type filterType = {
  year: {
    open: boolean;
    value: number[];
  };
  transmation: {
    open: boolean;
    value: string;
  };
  bodyStyle: {
    open: boolean;
    value: string;
  };
  make: {
    open: boolean;
    value: string;
  };
  model: {
    open: boolean;
    value: string;
  };
};

interface FilterProps {
  filters: filterType;
  setFilters: Dispatch<SetStateAction<filterType>>;
  applyFilters: () => void;
}

const FiltersComponent = ({
  filters,
  setFilters,
  applyFilters,
}: FilterProps) => {
  const openSide = "right";
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [open, setOpen] = useState<boolean>(false);
  const [years, setYears] = useState<number[]>([]);
  const [makes, setMakes] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]); 

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleFilters = (object: string, key: string, value: any) => {
    let newFilters = { ...filters };
    /* @ts-ignore */
    newFilters[object] = { ...newFilters[object], [key]: value };
    setFilters({ ...newFilters });
  };

  const handleApplyFilter = () => {
    applyFilters();
    setOpen(!open);
  };

  const handleYears = () => {
    const array: number[] = [];
    const StartYear = 1981;
    const CurrentYear = new Date().getFullYear();
    const CalculateYearlyDifference = CurrentYear - StartYear;
    for (let i = 0; i <= CalculateYearlyDifference; i++) {
      array.push(StartYear + i);
    }
    setYears(array);
  };

  useEffect(() => {
    handleYears();
  }, []);

  const style = {
    color: "var(--dark-color)",
    borderRadius: "50%",
  };

  const getMakes = () => {
    Services.Listing.GetMakes().then((res) => {
      res.status && setMakes(res.data);
    });
  };

  useEffect(() => getMakes(), []);

  const getModels = () => {
    Services.Listing.GetModels().then((res) => {
      res.status && setModels(res.data);
    });
  };

  useEffect(() => getModels(), []);

  return (
    <div>
      <ThemeProvider theme={themes.ButtonActiveTheme_2}>
        <Button
          variant="contained"
          onClick={toggleDrawer(true)}
          sx={{ textTransform: "capitalize", minWidth: "max-content" }}
        >
          <Tune className="me-1 sm:me-2" /> {t("filter_butn_text")}
        </Button>
      </ThemeProvider>
      <React.Fragment key={openSide}>
        <ThemeProvider theme={themes.MuiDrawerTheme}>
          <Drawer anchor={openSide} open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{
                width: 300,
              }}
              role="presentation"
            >
              <div className="flex flex-col gap-4 py-12 px-6">
                {/* title */}
                <div className="flex gap-1 justify-start items-center dark:text-white">
                  <Tune /> <h4 className="head">{t("fitler_title")}</h4>
                </div>
                {/* year */}
                <div className="pt-5">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      handleFilters("year", "open", !filters?.year?.open)
                    }
                  >
                    <p className="filterStyle">{t("filter_options.year")}</p>
                    <div>
                      {!filters?.year?.open ? (
                        <AddCircleOutline
                          fontSize="medium"
                          sx={style}
                          className="cursor-pointer"
                        />
                      ) : (
                        <RemoveCircleOutline
                          fontSize="medium"
                          className="cursor-pointer dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                  {filters?.year?.open && (
                    <div className="px-4">
                      <SliderWrapper
                        value={filters?.year?.value}
                        onChange={(
                          event: Event,
                          newValue: number | number[]
                        ) => {
                          if (!Array.isArray(newValue)) {
                            return;
                          }
                          handleFilters("year", "value", [
                            newValue[0],
                            newValue[1],
                          ]);
                        }}
                        valueLabelDisplay="on"
                        min={years?.length > 0 ? years[0] : "0000"}
                        max={
                          years?.length > 1
                            ? years[years?.length - 1]
                            : years?.length > 0
                              ? years[0]
                              : "0000"
                        }
                        sx={{
                          color: "var(--main-color)",
                          ".MuiSlider-rail": {
                            background: "white",
                            boxShadow:
                              "1px 1px 4px 0 rgba(0,0,0,0.25), -1px -1px 4px 0 rgba(0,0,0,0.25)",
                          },
                          ".MuiSlider-thumb:hover,  .MuiSlider-thumb:focus, .MuiSlider-thumb.Mui-active, .MuiSlider-thumb.Mui-focusVisible":
                            {
                              boxShadow:
                                "1px 1px 8px 0 rgba(0,0,0,0.2), -1px -1px 8px 0 rgba(0,0,0,0.2)",
                              border: "1px solid rgba(0,0,0,0.2)",
                            },
                        }}
                        disableSwap
                      />
                    </div>
                  )}
                </div>
                {/* transmission */}
                <div>
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      handleFilters(
                        "transmation",
                        "open",
                        !filters?.transmation?.open
                      )
                    }
                  >
                    <p className="filterStyle">
                      {t("filter_options.transmission_options.label")}
                    </p>
                    <div>
                      {!filters?.transmation?.open ? (
                        <AddCircleOutline
                          fontSize="medium"
                          className="cursor-pointer"
                          sx={style}
                        />
                      ) : (
                        <RemoveCircleOutline
                          fontSize="medium"
                          className="cursor-pointer dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                  {filters?.transmation?.open && (
                    <div className="pt-4">
                      <select
                        id="transmation"
                        className="htmlSelector"
                        onChange={(event: any) => {
                          handleFilters(
                            "transmation",
                            "value",
                            event?.target?.value
                          );
                        }}
                        value={filters?.transmation?.value}
                      >
                        <option value="">
                          {t("filter_options.transmission_options.label")}
                        </option>
                        <option value="all">
                          {t("filter_options.transmission_options.all")}
                        </option>
                        <option value="automatic">
                          {t("filter_options.transmission_options.automatic")}
                        </option>
                        <option value="manual">
                          {t("filter_options.transmission_options.manual")}
                        </option>
                      </select>
                    </div>
                  )}
                </div>
                {/* body style */}
                <div>
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      handleFilters(
                        "bodyStyle",
                        "open",
                        !filters?.bodyStyle?.open
                      )
                    }
                  >
                    <p className="filterStyle">
                      {t("filter_options.body_style_options.label")}
                    </p>
                    <div>
                      {!filters?.bodyStyle?.open ? (
                        <AddCircleOutline
                          fontSize="medium"
                          sx={style}
                          className="cursor-pointer"
                        />
                      ) : (
                        <RemoveCircleOutline
                          fontSize="medium"
                          className="cursor-pointer dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                  {filters?.bodyStyle?.open && (
                    <div className="pt-4">
                      <select
                        id="bodyStyle"
                        className="htmlSelector"
                        onChange={(event: any) => {
                          handleFilters(
                            "bodyStyle",
                            "value",
                            event?.target?.value
                          );
                        }}
                        value={filters?.bodyStyle?.value}
                      >
                        <option value="">
                          {t("filter_options.body_style_options.label")}
                        </option>
                        <option value="all">
                          {t("filter_options.body_style_options.all")}
                        </option>
                        <option value="coupe">
                          {t("filter_options.body_style_options.coupe")}
                        </option>
                        <option value="convertible">
                          {t("filter_options.body_style_options.convertible")}
                        </option>
                        <option value="hatchback">
                          {t("filter_options.body_style_options.hatchback")}
                        </option>
                        <option value="sedan">
                          {t("filter_options.body_style_options.sedan")}
                        </option>
                        <option value="suv/crossover">
                          {t("filter_options.body_style_options.suv/crossover")}
                        </option>
                        <option value="truck">
                          {t("filter_options.body_style_options.truck")}
                        </option>
                        <option value="van/minivan">
                          {t("filter_options.body_style_options.van/minivan")}
                        </option>
                        <option value="wagon">
                          {t("filter_options.body_style_options.wagon")}
                        </option>
                      </select>
                    </div>
                  )}
                </div>
                {/* make */}
                <div>
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      handleFilters("make", "open", !filters?.make?.open)
                    }
                  >
                    <p className="filterStyle">{t("facts_info.make")}</p>
                    <div>
                      {!filters?.make?.open ? (
                        <AddCircleOutline
                          fontSize="medium"
                          sx={style}
                          className="cursor-pointer"
                        />
                      ) : (
                        <RemoveCircleOutline
                          fontSize="medium"
                          className="cursor-pointer dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                  {filters?.make?.open && (
                    <div className="pt-4">
                      <select
                        id="make"
                        className="htmlSelector"
                        onChange={(event: any) => {
                          handleFilters("make", "value", event?.target?.value);
                        }}
                        value={filters?.make?.value}
                      >
                        <option value="">{t("facts_info.make")}</option>
                        <option value="all">
                          {t("filter_options.body_style_options.all")}
                        </option>
                        {makes?.length > 0 &&
                          makes?.map((item) => (
                            <option value={item.make} key={item.id}>
                              {item.make}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                {/* model */}
                <div>
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      handleFilters("model", "open", !filters?.model?.open)
                    }
                  >
                    <p className="filterStyle">{t("facts_info.model")}</p>
                    <div>
                      {!filters?.model?.open ? (
                        <AddCircleOutline
                          fontSize="medium"
                          sx={style}
                          className="cursor-pointer"
                        />
                      ) : (
                        <RemoveCircleOutline
                          fontSize="medium"
                          className="cursor-pointer dark:text-white"
                        />
                      )}
                    </div>
                  </div>
                  {filters?.model?.open && (
                    <div className="pt-4">
                      <select
                        id="model"
                        className="htmlSelector"
                        onChange={(event: any) => {
                          handleFilters("model", "value", event?.target?.value);
                        }}
                        value={filters?.model?.value}
                      >
                        <option value="">{t("facts_info.model")}</option>
                        <option value="all">
                          {t("filter_options.body_style_options.all")}
                        </option>
                        {models?.length > 0 &&
                          models?.map((item) => (
                            <option value={item.model} key={item.id}>
                              {item.model}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                {/* submit butn */}
                <div className="flex justify-center mt-8">
                  <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                    <Button
                      variant="contained"
                      onClick={() => handleApplyFilter()}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {t("filter_options.apply_filter_butn_text")}{" "}
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Box>
          </Drawer>
        </ThemeProvider>
      </React.Fragment>
    </div>
  );
};

export default FiltersComponent;
