import { useState, memo, useEffect } from "react";
import { AuctionCardProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "./CircleIcon.component";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Auction } from "../main";
import moment from "moment";
import { ConvertToLocal } from "../../utils/CalculateDays";
import { ListingDetail } from "../dashboard";

const AuctionCardComponent = memo(function AuctionCardComponent({
  item,
  showDetailsInProp,
  isLisitng,
}: AuctionCardProps) {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [auctionData, setAuctionData] = useState<any>({});
  const url = `/auctions/${
    auctionData?.year?.replace(/\s+/g, "-") +
    "-" +
    auctionData?.make?.replace(/\s+/g, "-") +
    "-" +
    auctionData?.model?.replace(/\s+/g, "-") +
    (auctionData?.title ? "-" + auctionData?.title?.replace(/\s+/g, "-") : "")
  }/${auctionData?.uuid}`;
  const state = { uuid: auctionData?.uuid };
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    item && setAuctionData(item);
  }, [item]);

  const isAutionEnd = (data: any) => {
    if (
      data?.auction?.status === "live" &&
      // Utils.CalculateDays(data?.auction?.auction_end) > 0
      Utils.CalculateDays(moment(item?.auction?.auction_end).local()) > 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      {showDetailsInProp ? (
        <div
          className="decoration-none"
          onClick={(e) => {
            setShowPopup(true);
          }}
        >
          <div className="listingCard">
            <div
              className={
                !isAutionEnd(auctionData) &&
                auctionData?.has_reserve_price === false
                  ? "ribbon ribbon-top-right "
                  : "hidden"
              }
            >
              <span className="p1">{t("card_data.no_reserve")}</span>
            </div>
            <div>
              <h4 className="truncate pe-8 head ">
                {auctionData?.title ? " " + auctionData?.title : ""}
              </h4>
              <div className="cardSubTitle">
                {auctionData?.make}
                <CircleIcon className="dot" />
                {auctionData?.model + " " + auctionData?.year}
                <CircleIcon className="dot" />
                {auctionData?.mileage}
                {" KM"}
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                <img
                  src={`${process.env.REACT_APP_HOST_API_KEY}/${
                    auctionData?.photos?.exterior[0] || ""
                  }`}
                  alt={
                    auctionData?.make +
                    " " +
                    auctionData?.year +
                    " " +
                    auctionData?.model +
                    " " +
                    auctionData.title
                  }
                  className=" cardh "
                  loading="lazy"
                />
                <Grid container spacing={0} className="rounded-b-xl p-0 h-full">
                  {!isAutionEnd(auctionData) && (
                    <Grid
                      item
                      xs={5.98}
                      className="text-center bg-yellow-300 p-0 py-1"
                    >
                      <div className={`font-bold text-black text-lg`}>
                        {auctionData?.auction?.auction_end &&
                        Utils.CalculateDays(auctionData?.auction?.auction_end) >
                          1 ? (
                          Utils.CalculateDays(
                            auctionData?.auction?.auction_end
                          ).toFixed(0) +
                          " " +
                          (Utils.CalculateDays(
                            auctionData?.auction?.auction_end
                          ) > 1
                            ? t("card_data.days")
                            : t("card_data.day"))
                        ) : (
                          <Countdown
                            date={ConvertToLocal(
                              auctionData?.auction?.auction_end
                            )}
                            daysInHours={true}
                          />
                        )}
                      </div>
                      <div className="text-gray-500 text-sm">
                        {t("card_data.time_text")}
                      </div>
                    </Grid>
                  )}
                  {!isAutionEnd(auctionData) && <Grid item xs={0.04} />}
                  <Grid
                    item
                    xs={!isAutionEnd(auctionData) ? 5.98 : 12}
                    className="text-center bg-yellow-300 p-0  py-1"
                  >
                    <div className="font-bold text-black text-lg">
                      {auctionData?.current_bid?.price
                        ? auctionData?.current_bid?.price
                        : 0}{" "}
                      CHF
                    </div>
                    <div className="text-gray-500 text-sm">
                      {auctionData?.auction?.status === "live"
                        ? item.stats.bids +
                          " " +
                          t(
                            "card_data.auction_status." +
                              (item.stats.bids > 1 ? "bids" : "bid")
                          )
                        : auctionData?.auction?.status === "sold"
                          ? t("auction_details_page.auction_status.sold")
                          : t("auction_details_page.auction_status.bid")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="flex flex-col gap-2 p1">
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.body_type")}</div>
                <div className="font-semibold">{auctionData?.body_style}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.engine")}</div>
                <div className="font-semibold">{auctionData?.engine}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.transmission")}</div>
                <div className="font-semibold">{auctionData?.transmission}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium text-wrap">
                  {t("card_data.color")}
                </div>
                <div className="font-semibold">
                  <div className="flex -space-x-2 items-center justify-center">
                    <Tooltip
                      title={
                        t("details_submission_page.2xwpepu29") +
                        " : " +
                        auctionData?.interior_color
                      }
                    >
                      <span
                        className={`w-4 h-4 border-2 border-black rounded-full drop-shadow-sm hovershadow z-[1] hover:z-[999] hover:drop-shadow-md interiorHover`}
                        style={{
                          background: auctionData?.interior_color
                            ? auctionData?.interior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor =
                            auctionData?.interior_color
                              ? auctionData?.interior_color
                              : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                    <Tooltip
                      title={
                        t("details_submission_page.e5gzqci3g") +
                        " : " +
                        auctionData?.exterior_color
                      }
                    >
                      <span
                        className={`w-4 h-4 border-2 border-black rounded-full drop-shadow-sm hovershadow hover:z-[999] z-[2] hover:drop-shadow-md exteriorHover`}
                        style={{
                          background: auctionData?.exterior_color
                            ? auctionData?.exterior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor =
                            auctionData?.exterior_color
                              ? auctionData?.exterior_color
                              : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.locaiton")}</div>
                <div className="font-semibold">
                  {auctionData?.zip_code || ""}{" "}
                  {auctionData?.city_and_province || ""}{" "}
                  {auctionData?.country || ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link to={url} state={state} className="decoration-none">
          <div className="listingCard">
            <div
              className={
                !isAutionEnd(auctionData) &&
                auctionData?.has_reserve_price === false
                  ? "ribbon ribbon-top-right "
                  : "hidden"
              }
            >
              <span className="p1">{t("card_data.no_reserve")}</span>
            </div>
            <div>
              <h4 className="truncate pe-8 head">
                {auctionData?.title ? " " + auctionData?.title : ""}
              </h4>
              <div className="text-gray-500 p0 truncate flex justify-start items-center gap-1 md:gap-2 capitalize">
                {auctionData?.make}
                <CircleIcon className="dot" />
                {auctionData?.model + " " + auctionData?.year}
                <CircleIcon className="dot " />
                {auctionData?.mileage}
                {" KM"}
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                <img
                  src={`${process.env.REACT_APP_HOST_API_KEY}/${
                    auctionData?.photos?.exterior[0] || ""
                  }`}
                  alt={
                    auctionData?.make +
                    " " +
                    auctionData?.year +
                    " " +
                    auctionData?.model +
                    " " +
                    auctionData.title
                  }
                  className=" cardh "
                  loading="lazy"
                />
                <Grid container spacing={0} className="rounded-b-xl p-0 h-full">
                  {!isAutionEnd(auctionData) && (
                    <Grid
                      item
                      xs={5.98}
                      className="text-center bg-yellow-300 p-0 py-1"
                    >
                      <div className={`font-bold text-black text-lg`}>
                        {auctionData?.auction?.auction_end &&
                        Utils.CalculateDays(auctionData?.auction?.auction_end) >
                          1 ? (
                          Utils.CalculateDays(
                            auctionData?.auction?.auction_end
                          ).toFixed(0) +
                          " " +
                          (Utils.CalculateDays(
                            auctionData?.auction?.auction_end
                          ) > 1
                            ? t("card_data.days")
                            : t("card_data.day"))
                        ) : (
                          <Countdown
                            date={ConvertToLocal(
                              auctionData?.auction?.auction_end
                            )}
                            daysInHours={true}
                          />
                        )}
                      </div>
                      <div className="text-gray-500 text-sm">
                        {t("card_data.time_text")}
                      </div>
                    </Grid>
                  )}
                  {!isAutionEnd(auctionData) && <Grid item xs={0.04} />}
                  <Grid
                    item
                    xs={!isAutionEnd(auctionData) ? 5.98 : 12}
                    className="text-center bg-yellow-300 p-0  py-1"
                  >
                    <div className="font-bold text-black text-lg">
                      {auctionData?.current_bid?.price
                        ? auctionData?.current_bid?.price
                        : 0}{" "}
                      CHF
                    </div>
                    <div className="text-gray-500 text-sm">
                      {auctionData?.auction?.status === "live"
                        ? item.stats.bids +
                          " " +
                          t(
                            "card_data.auction_status." +
                              (item.stats.bids > 1 ? "bids" : "bid")
                          )
                        : auctionData?.auction?.status === "sold"
                          ? t("auction_details_page.auction_status.sold")
                          : t("auction_details_page.auction_status.bid")}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="flex flex-col gap-2 p1">
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.body_type")}</div>
                <div className="font-semibold">{auctionData?.body_style}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.engine")}</div>
                <div className="font-semibold">{auctionData?.engine}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.transmission")}</div>
                <div className="font-semibold">{auctionData?.transmission}</div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium text-wrap">
                  {t("card_data.color")}
                </div>
                <div className="font-semibold">
                  <div className="flex -space-x-2 items-center justify-center">
                    <Tooltip
                      title={
                        t("details_submission_page.2xwpepu29") +
                        " : " +
                        auctionData?.interior_color
                      }
                    >
                      <span
                        className={`w-4 h-4 border-2 border-black rounded-full drop-shadow-sm hovershadow z-[1] hover:z-[999] hover:drop-shadow-md interiorHover`}
                        style={{
                          background: auctionData?.interior_color
                            ? auctionData?.interior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor =
                            auctionData?.interior_color
                              ? auctionData?.interior_color
                              : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                    <Tooltip
                      title={
                        t("details_submission_page.e5gzqci3g") +
                        " : " +
                        auctionData?.exterior_color
                      }
                    >
                      <span
                        className={`w-4 h-4 border-2 border-black rounded-full drop-shadow-sm hovershadow hover:z-[999] z-[2] hover:drop-shadow-md exteriorHover`}
                        style={{
                          background: auctionData?.exterior_color
                            ? auctionData?.exterior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor =
                            auctionData?.exterior_color
                              ? auctionData?.exterior_color
                              : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center truncate gap-1">
                <div className="font-medium">{t("card_data.locaiton")}</div>
                <div className="font-semibold">
                  {auctionData?.zip_code || ""}{" "}
                  {auctionData?.city_and_province || ""}{" "}
                  {auctionData?.country || ""}
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
      {showPopup && (
        <div>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={showPopup}
            hideBackdrop={true}
            onClose={() => {
              setShowPopup(false);
            }}
            sx={themes.JoyModalStyle_2}
          >
            <Sheet
              variant="outlined"
              sx={{
                ...themes.JoyModalSheetStyle_2,
                minWidth: "90vw",
                minHeight: "90vh",
              }}
            >
              <ModalClose variant="outlined"  size="sm" sx={themes.JoyModalCloseStyle_2} />
              <div>
                {isLisitng ? (
                  <ListingDetail list_id={auctionData?.id} />
                ) : (
                  <Auction
                    list_id={auctionData?.uuid}
                    setShowPopup={setShowPopup}
                  />
                )}
              </div>
            </Sheet>
          </Modal>
        </div>
      )}
    </>
  );
});

export default AuctionCardComponent;
