import { useState, useEffect } from "react";
import { ThemeProvider, Button, Pagination } from "@mui/material";
import Utils from "../../utils";
import { useNavigate, Link } from "react-router-dom";
import Services from "../../services";
import moment from "moment";
import { PreLoaderComponent } from "../../components";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import Theme from "../../utils/Theme.utils";
import { navigateListing } from "../../components/ApplicationSidebar.component";

interface listingType {
  Applied: any[];
  "In Progress": any[];
  Rejected: any[];
  Current: any[];
  Past: any[];
  Live: any[];
  Sold: any[];
}

const Listings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isUserLogedIn = isUser_loged_in();
  const themes = Utils.Themes();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [myListing, setMyListing] = useState<any | null>({
    Applied: [],
    "In Progress": [],
    Rejected: [],
    Current: [],
    Past: [],
    Live: [],
    Sold: [],
  });

  const getMyListing = () => {
    setLoading(true);
    Services.Listing.MyListing(offset, limit)
      .then((res: any) => {
        setLoading(false);
        if (res.status) {
          setTotal(Math.ceil(res.total / limit));
          let myListingData: listingType = {
            Applied: [],
            "In Progress": [],
            Rejected: [],
            Current: [],
            Past: [],
            Live: [],
            Sold: [],
          };
          const data = res.data;
          data?.length > 0 &&
            data?.map((list_item: any, index: number) => {
              list_item.application_status === "pending"
                ? myListingData["Applied"].push(list_item)
                : list_item.application_status === "approved" &&
                    (list_item.details_submission_status === "pending" ||
                      (list_item.details_submission_status === "approved" &&
                        !list_item.review))
                  ? myListingData["In Progress"].push(list_item)
                  : list_item.application_status === "rejected" ||
                      list_item.details_submission_status === "rejected"
                    ? myListingData["Rejected"].push(list_item)
                    : list_item.details_submission_status === "approved" &&
                        list_item.review &&
                        !list_item?.auction?.auction_end
                      ? myListingData["Current"].push(list_item)
                      : list_item?.auction?.auction_end &&
                          list_item?.auction?.status === "live"
                        ? myListingData["Live"].push(list_item)
                        : list_item?.auction?.auction_end &&
                            list_item?.auction?.status === "sold"
                          ? myListingData["Sold"].push(list_item)
                          : list_item?.auction?.auction_end &&
                            list_item?.auction?.status === "past" &&
                            myListingData["Past"].push(list_item);
            });
          setMyListing(data?.length > 0 ? myListingData : null);
        }
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyListing();
  }, [offset, limit, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("listing_page.title")} />
      <div className="w-full lg:w-10/12 min-h-96 md:mb-4 lg:mb-8">
        <div className="m-auto w-full md:w-8/9 lg:w-7/9">
          <div className="py-4 border-b dark:border-[--gray] w-full ">
            <h1 className="head font-bold">{t("listing_page.qrq6836ca")}</h1>
          </div>
          <div className=" py-2 sm:py-4 lg:py-6 flex justify-center items-center">
            {loading && <PreLoaderComponent />}
          </div>
          <div className="flex flex-col justify-center items-start gap-2">
            {!loading &&
              (myListing ? (
                Object.keys(myListing)?.length > 0 &&
                Object.keys(myListing)?.map((key: string) => (
                  <>
                    {myListing[key] &&
                      Array.isArray(myListing[key]) &&
                      myListing[key]?.length > 0 && (
                        <>
                          <h3
                            className="text-[var(--main-color)] dark:text-[var(--main-color)] head font-bold tracking-wide py-2 pt-4 px-3"
                            key={key}
                          >
                            {key}
                          </h3>
                          {myListing[key]?.map(
                            (list_item: any, index: number) => {
                              return (
                                <Link
                                  to={`${navigateListing(list_item)}`}
                                  state={{ list_id: list_item.uuid }}
                                  className="py-1 sm:py-3 border-b dark:border-[--gray] w-full  myListing hover:shadow-sm px-3"
                                  key={key + index}
                                >
                                  <div className="  w-full  flex flex-col sm:flex-row  justify-center sm:justify-between items-start sm:items-end ">
                                    <div className="flex flex-col">
                                      <h3 className="p0  hover:underline">
                                        {list_item.year} {list_item?.make}{" "}
                                        {list_item.model}{" "}
                                        {list_item.title ? list_item.title : ""}
                                      </h3>
                                    </div>
                                    <p className="textGray sm:ml-auto p1">
                                      {t("listing_page.ljrmm8tjw")}{" "}
                                      {moment(list_item.updated_at).local().format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                </Link>
                              );
                            }
                          )}
                        </>
                      )}
                  </>
                ))
              ) : (
                <p className="textGray pb-2 info_message">
                  {t("listing_page.ix799and9")}{" "}
                </p>
              ))}
          </div>
          <div className="py-2 sm:py-4 lg:py-8 flex flex-col sm:flex-row justify-between items-center gap-2">
            <div className=" w-full sm:w-fit">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "capitalize", padding: "0.5rem 2rem" }}
                  onClick={() =>
                    isUserLogedIn
                      ? navigate("/sell-car/submit")
                      : document.getElementById("signin")?.click()
                  }
                >
                  {myListing
                    ? t("listing_page.zg31hohg0")
                    : t("listing_page.vc6trrpvf")}
                </Button>
              </ThemeProvider>
            </div>
            {!loading && total > 1 && (
              <div className="order-1">
                <Pagination
                  count={total}
                  hidePrevButton
                  hideNextButton
                  page={page}
                  onChange={(e: any, pageNum: number) => {
                    setPage(pageNum);
                    setOffset((pageNum - 1) * limit);
                  }}
                  shape="rounded"
                  sx={themes.MuiPagenationStyle_2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listings;
