import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
// import i18next from 'i18next';
import "./i18n";
// import "intl-pluralrules";
import Context from "./context";
import { ThemeProvider } from "@mui/material";
import Theme from "./utils/Theme.utils";

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(
    <Context.LanguageContextProvider>
      <Context.ThemeContextProvider>
        {/* <ThemeProvider theme={Theme.MergedTheme}> */}
        <App />
        {/* </ThemeProvider> */}
      </Context.ThemeContextProvider>
    </Context.LanguageContextProvider>,
    rootElement
  );
} else {
  render(
    <Context.LanguageContextProvider>
      <Context.ThemeContextProvider>
        {/* <ThemeProvider theme={Theme.BreakPoints}> */}
        <App />
        {/* </ThemeProvider> */}
      </Context.ThemeContextProvider>
    </Context.LanguageContextProvider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
